import { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";

function SportsbooksByStatePage() {

    const [sportsbooksData, setSportsbooksData] = useState(null)

    useEffect(() => {

        async function getSportsbooksByState() {
            const docRef = doc(db, 'variables', 'sportsbooksByState')
            const docSnap = await getDoc(docRef)
            console.log('sportsbooks by state: ', docSnap.data())
            setSportsbooksData(docSnap.data())
        }
        getSportsbooksByState()

    }, [])

    if (!sportsbooksData) {
        return (
            <div className="h-screen">
                <p>Loading...</p>
            </div>
        )
    }

    const orderedStates = Object.keys(sportsbooksData.byState).sort((a, b) => {
        if (a < b) {
            return -1
        } else if (b > a) {
            return 1
        } else {
            return 0
        }
    })
    const orderedBooks = Object.keys(sportsbooksData.bySportsbook).sort((a, b) => {
        const aCount = sportsbooksData.bySportsbook[a].length
        const bCount = sportsbooksData.bySportsbook[b].length
        if (aCount > bCount) {
            return -1
        } else if (aCount < bCount) {
            return 1
        } else {
            return a > b ? -1 : 1
        }
    })

    return (
        <div className="h-screen overflow-x">
            <table className="w-full overflow-scroll table-auto border-collapse border border-slate-300">
                <thead className="bg-gray-100 sticky top-0 z-10">
                    <tr>
                        <th className="sticky left-0 border border-slate-300 bg-gray-100 z-20"></th>
                        {
                            orderedStates.map((k, i) => {
                                return (
                                    <th key={k} className="border border-slate-300 min-w-10">{k}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody className="sticky left-0">
                    {
                        orderedBooks.map((k, i) => {
                            return (
                                <tr key={k}>
                                    <td className="sticky left-0 border border-slate-300 bg-white z-10">{k}</td>
                                    {
                                        orderedStates.map((s, j) => {
                                            // const hasBook = sportsbooksData.byState[s].includes(k)
                                            const hasBook = sportsbooksData.bySportsbook[k].includes(s)
                                            return (
                                                <td key={s} className="border border-slate-300 min-w-10">{hasBook ? "X" : ""}</td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    {/* <tr>
                        <td>CA</td>
                        <td>William Hill</td>
                    </tr>
                    <tr>
                        <td>CO</td>
                        <td>William Hill</td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    )
}

export default SportsbooksByStatePage;