import { React, useEffect, useState } from "react";
import { db } from "./firebase/firebase";
import GameDataComponent from "./components/GameDataComponent";
import NavigationBar, { sportTitles } from "./components/NavigationBar";
import MenuComponent from "./components/MenuComponent";
import { doc, getDoc } from "firebase/firestore";
import SelectSportsbooksModal from "./components/SelectSportsbooksModal";
import downCaret from './assets/down_caret.png'
import GameDetailsModal from "./components/GameDetailsModal";
import NoGamesComponent from "./components/NoGamesComponent";
import useSportLeagueData from "./firebase/useSportLeagueData";
import { logout } from "./firebase/firebase"
import { useSearchParams } from "react-router-dom";

const activeEspnSportLeagues = ["baseball_mlb", "basketball_wnba"]

function TodaysPicks({ user, userDoc, showBetaFeatures }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const fixedDate = searchParams.get('date')

    const [showMenu, setShowMenu] = useState(false);
    const [showSportsbookSelector, setShowSportsbookSelector] = useState(false);
    const [highlightedGame, setHighlightedGame] = useState(null);

    const [previewEnded, setPreviewEnded] = useState(false)

    let initialSport = userDoc?.currentDefaultSport
    if (!initialSport || !activeEspnSportLeagues.includes(initialSport)) {
        initialSport = "baseball_mlb"
    }
    const [selectedSport, setSelectedSport] = useState(initialSport);

    const [sportsbooksData, setSportsbooksData] = useState(null)
    const [sportsbooksByState, setSportsbooksByState] = useState(userDoc?.stateLocation ?? null)
    const [includedSportsbooks, setIncludedSportsbooks] = useState(userDoc?.sportsbooks ?? null)

    const mlbLoader = useSportLeagueData('baseball_mlb', includedSportsbooks, fixedDate)
    const wnbaLoader = useSportLeagueData('basketball_wnba', includedSportsbooks, fixedDate)
    // const ncaabLoader = useSportLeagueData('basketball_mens-college-basketball', includedSportsbooks, fixedDate)

    // const [deadline, setDeadline] = useState(new Date(userDocLoader?.document?.createdAt) ?? null)
    const [manualViewPaywall, setManualViewPaywall] = useState(false)

    useEffect(() => {
        // setDeadline(new Date().getTime() + 1000 * 60 * 60 * 24)
        // setDeadline()

        console.log('fixtedDate: ', fixedDate)

        if (userDoc?.previewEndsAt) {
            const now = new Date()
            setPreviewEnded(userDoc?.previewEndsAt?.toDate() < now)
        }
        

        async function getSportsbooksByState() {
            const docRef = doc(db, 'variables', 'sportsbooksByState')
            const docSnap = await getDoc(docRef)
            console.log('sportsbooks by state: ', docSnap.data())
            if (includedSportsbooks === null) {
                setIncludedSportsbooks(Object.keys(docSnap.data().bySportsbook))
            }
            setSportsbooksData(docSnap.data())
        }
        getSportsbooksByState()

        // const ds = localStorage.getItem('defaultSport')
        // if (ds) {
        //     setSelectedSport(ds)
        // } else {
        //     setSelectedSport("hockey_nhl")
        // }
        // const defaultState = localStorage.getItem('defaultState')
        // if (defaultState) {
        //     setSportsbooksByState(defaultState)
        // }
        // const defaultSportsbooks = localStorage.getItem('defaultSportsbooks')
        // if (defaultSportsbooks) {
        //     setIncludedSportsbooks(defaultSportsbooks.split(','))
        // }
    }, [])

    function menuClicked() {
        setShowMenu(!showMenu)
    }

    function hasMembershipAccess() {
        return userDoc?.invoiceStatus === 'paid' || userDoc?.invoiceStatus === 'free'
    }

    function showPaywall() {
        if (hasMembershipAccess()) {
            return false
        } else if (manualViewPaywall) {
            return true
        } else {
            return previewEnded
        }
        // return userDoc?.invoiceStatus !== 'paid' && userDoc?.invoiceStatus !== 'free' && userDoc?.previewEndsAt?.toDate() < now
    }

    const modalIsOpen = (showSportsbookSelector || highlightedGame !== null)

    async function setStateAndSportsbooks(state, sportsbooks) {
        setSportsbooksByState(state)
        setIncludedSportsbooks(sportsbooks)
        await userDoc.updateStateAndSportsbooks(state, sportsbooks)
    }

    function previewDeadline() {
        if (hasMembershipAccess()) {
            return null
        } else {
            return userDoc?.previewEndsAt?.toDate()
        }
    }

    function previewCountdownClicked() {
        setManualViewPaywall(true)
    }

    function paywallBackgroundClicked() {
        setManualViewPaywall(false)
    }

    function gameClicked(gameData) {
        console.log(`game clicked: ${gameData.homeTeam} vs. ${gameData.awayTeam}`)
        setHighlightedGame(gameData);
    }

    if (currentLoader().loading) {
        return (
            <div >
                <p style={{ color: "black", paddingTop: "1em" }}>Loading...</p>
            </div>)
    }

    async function updateSelectedSport(s) {
        console.log('new sport selection ', s)
        setSelectedSport(s)
        // await userDocLoader.document.updateDefaultSport(s)
    }

    function currentLoader() {
        return [mlbLoader, wnbaLoader].find(d => {
            return d.sportLeague === selectedSport
        })
    }

    function sportsbooksFilterText() {
        if (!sportsbooksData) {
            return 'Loading...'
        } else if (sportsbooksByState !== null) {
            const totalavailable = sportsbooksData?.byState[sportsbooksByState]?.length
            return `${sportsbooksByState} Sportsbooks (${includedSportsbooks.length} of ${totalavailable} selected)`
        } else {
            const totalavailable = Object.keys(sportsbooksData?.bySportsbook)?.length
            return 'All Sportsbooks (' + includedSportsbooks.length + ' of ' + totalavailable + ' selected)'
        }
    }

    function GameList() {
        const games = currentLoader().processedData
            .filter(g => {
                return g.predictions && g.predictions[0]?.homeWinPct < 0.99 && g.predictions[0]?.awayWinPct < 0.99 &&
                    (g.statusName === "STATUS_SCHEDULED" || showBetaFeatures)
                // g.rawdate.getDate() === now.getDate() &&
                // g.rawdate.getMonth() === now.getMonth() &&
                // g.rawdate.getFullYear() === now.getFullYear() &&
            })

        if (games.length === 0) {
            return (
                <NoGamesComponent leagueName={sportTitles[selectedSport]} />
            )
        } else {
            return (
                <div className="grow flex flex-col space-y-2 w-full">
                    {
                        games.map(s => {
                            return <GameDataComponent onClick={() => gameClicked(s)} gamedata={s} key={s.id} bestMarketOutcomeOdds={currentLoader().bestMarketOutcomeOdds[s.id]} worstMarketOutcomeOdds={currentLoader().worstMarketOutcomeOdds[s.id]} americanStyleOdds={true} showBetaFeatures={showBetaFeatures} />
                        })
                    }
                </div>
            )
        }


    }

    return (
        <div>
            {
                showPaywall() &&
                <div className="z-40 fixed flex flex-col items-center w-full h-full" onClick={() => paywallBackgroundClicked()}>
                    <div className="flex flex-col bg-white items-center rounded-lg border mt-14 px-2 py-4">
                        {
                            !manualViewPaywall &&
                            <label className="text-sm text-gray-600">Your Preview Has Ended</label>
                        }
                        <label className="text-2xl font-bold mb-3">Choose Your Plan</label>
                        <stripe-pricing-table
                            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
                            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                            client-reference-id={user.uid}>
                        </stripe-pricing-table>
                        <button className="text-sm font-semibold mt-3" onClick={() => logout()}>Log out</button>
                        <label className="text-xs text-gray-600">{userDoc?.email}</label>
                    </div>
                </div>
            }
            <div className={`min-h-screen w-full flex flex-col justify-between ${showMenu ? 'bg-white' : 'bg-gray-100'} z-0 ${ showPaywall() ? 'blur-md':''}`}>

                <SelectSportsbooksModal isOpen={showSportsbookSelector} onClose={() => setShowSportsbookSelector(false)} sportsbooksData={sportsbooksData} currentlySelectedState={sportsbooksByState} currentlySelectedSportsbooks={includedSportsbooks} updateSelectedStateSportsbooks={setStateAndSportsbooks} />
                <GameDetailsModal isOpen={highlightedGame !== null} handleClose={() => setHighlightedGame(null)} gameData={highlightedGame} bestMarketOutcomeOdds={currentLoader().bestMarketOutcomeOdds[highlightedGame?.id]} includedSportsbooks={includedSportsbooks} americanStyleOdds={true} />
                <div>
                    <div className={`bg-white shadow sticky top-0 left-0 right-0 z-20 ${modalIsOpen ? 'hidden' : ''}`}>
                        <div className="max-w-4xl m-auto">
                            <NavigationBar menuShown={showMenu} handleMenuClick={menuClicked} previewDeadline={previewDeadline()} handlePreviewClick={previewCountdownClicked} handleEndOfPreview={() => setPreviewEnded(true)} />
                            <MenuComponent email={user.email} show={showMenu} />
                            <div className={`bg-white ${showMenu ? 'hidden' : ''}`}>
                                <div className=" max-w-4xl m-auto">
                                    <button className="px-4 py-2 flex flex-row items-center text-sm font-medium border border-black rounded" onClick={() => setShowSportsbookSelector(true)}>
                                        {
                                            sportsbooksFilterText()
                                        }
                                        <img src={downCaret} className="h-3 ml-2" />
                                    </button>
                                </div>
                            </div>
                            <div className={`bg-white  ${showMenu ? 'hidden' : ''}`}>
                                <div className="flex flex-row items-center h-full max-w-4xl m-auto">
                                    {
                                        activeEspnSportLeagues.map(s => {
                                            return <button key={s} className={`border-b-4 ${s === selectedSport ? "border-charcoal font-bold" : "border-transparent font-light"} text-sm text-black px-4 py-2`} onClick={() => updateSelectedSport(s)}>{sportTitles[s]}</button>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`flex flex-col grow relative max-w-4xl m-auto py-2 ${showMenu || modalIsOpen ? 'hidden' : ''}`}>
                        <GameList />
                    </div>
                </div>
                <div className={`h-6 bg-white text-xs text-gray-600 sticky bottom-0 left-0 right-0 z-20 flex items-center space-x-2 pl-2 ${showMenu || modalIsOpen || !showBetaFeatures ? 'hidden' : ''}`}>
                    <div>{`odds from ${currentLoader().oddsLastUpdatedText},`}</div>
                    <div>{`predictions from ${currentLoader().gamesLastUpdatedText}`}</div>
                </div>
            </div>
        </div>
    )
}

const predictorDisplayNames = {
    'espn': 'ESPN',
    'numberfire': 'NumberFire'
}

export default TodaysPicks;
export { predictorDisplayNames }