import { useEffect, useState } from "react"

function PreviewCountdown({ deadline, handleClick, handleEndOfPreview }) {

    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    function getTime() {
        const now = new Date().getTime()
        const time = deadline - now
        // const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const hours = Math.floor((time) / (1000 * 60 * 60))
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((time % (1000 * 60)) / 1000)
        setHours(hours)
        setMinutes(minutes)
        setSeconds(seconds)
        if (time < 0) {
            handleEndOfPreview()
        }
    }

    function TimeComponentLabel({ timeInt, letter }) {
        return (
            <label className="text-xs font-semibold text-white w-6 text-right">
                {timeInt + letter}
            </label>
        )
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getTime()
        }, 1000)
        return () => clearInterval(interval)
    }, [deadline])

    return (
        <div className={`py-px w-32 items-center flex flex-col rounded-full bg-charcoal ${ deadline ? '':'hidden' }`} onClick={() => handleClick()}>
            <label className="text-xs font-bold text-white text-opacity-80">
                Previewing
            </label>
            <div className="flex flex-row space-x-1">
                <TimeComponentLabel timeInt={hours} letter="h" />
                <TimeComponentLabel timeInt={minutes} letter="m" />
                <TimeComponentLabel timeInt={seconds} letter="s" />
            </div>
        </div>
    )
}

export default PreviewCountdown