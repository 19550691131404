import { useParams, useLocation } from "react-router-dom"
import useFirestoreQuery from "../firebase/useFirestoreQuery"
import useSportLeagueDateData from "../firebase/useSportLeagueDateData"
import { arbitragePct, getTeamPicSrc, profitPct } from "./GameDataComponent"
import { getAmericanOdds } from "../PicksData"

export function bestMLs(odds, oddsApiHomeTeam, oddsApiAwayTeam, oddsSince) {
    let besthome = 0
    let bestaway = 0
    let besthomeprovider = ''
    let bestawayprovider = ''
    let besthomeLastUpdated = ''
    let bestawayLastUpdated = ''
    let lastUpdated = ''

    if (!odds || !odds.bookmakers) {
        return { besthome, besthomeprovider, bestaway, bestawayprovider, besthomeLastUpdated, bestawayLastUpdated, lastUpdated }
    }

    // find last updated odds
    const bookmakersWithOdds = odds.bookmakers.filter(b => {
        const h2h = b.markets.find(m => m.key === 'h2h')
        return (h2h && h2h.outcomes)
    })
    const lastDate = bookmakersWithOdds.map(b => new Date(b.last_update)).reduce((a, b) => a > b ? a : b, null)
    lastUpdated = lastDate?.toLocaleTimeString() ?? '?'

    const apiHomeTeam = oddsApiHomeTeam ?? odds.oddsApiHomeTeam
    const apiAwayTeam = oddsApiAwayTeam ?? odds.oddsApiAwayTeam

    const outcomeSummaries = {}
    const homeOutcomes = []
    const awayOutcomes = []

    const secondsMargin = 90

    let filteredOdds = bookmakersWithOdds
    if (oddsSince) {
        filteredOdds = bookmakersWithOdds.filter(b => { return (new Date(b.last_update)) > oddsSince })
    } else {
        filteredOdds = bookmakersWithOdds.filter(b => { return (lastDate - new Date(b.last_update)) / 1000 < secondsMargin })
    }

    filteredOdds
    .forEach(b => {
        const h2h = b.markets.find(m => m.key === 'h2h')
        if (h2h && h2h.outcomes) {
            h2h.outcomes.forEach(o => {
                if (o.name === apiHomeTeam) {
                    homeOutcomes.push({ price: o.price, provider: b.key, last_update: b.last_update})
                } else if (o.name === apiAwayTeam) {
                    awayOutcomes.push({ price: o.price, provider: b.key, last_update: b.last_update})
                }

                // if (!outcomeSummaries[o.name]) {
                //     outcomeSummaries[o.name] = { count: 1, totalPrice: o.price }
                // } else {
                //     outcomeSummaries[o.name].count++
                //     outcomeSummaries[o.name].totalPrice += o.price
                // }
                if (o.name === apiHomeTeam && o.price > besthome) {
                    besthome = o.price
                    besthomeprovider = b.key
                    besthomeLastUpdated = b.last_update
                } else if (o.name === apiAwayTeam && o.price > bestaway) {
                    bestaway = o.price
                    bestawayprovider = b.key
                    bestawayLastUpdated = b.last_update
                }
            })
        }
    })

    // const avghome = outcomeSummaries[apiHomeTeam].totalPrice / outcomeSummaries[apiHomeTeam].count
    // const avgaway = outcomeSummaries[apiAwayTeam].totalPrice / outcomeSummaries[apiAwayTeam].count

    // const counthome = outcomeSummaries[apiHomeTeam].count
    // const countaway = outcomeSummaries[apiAwayTeam].count

    const homePrices = homeOutcomes.map(o => o.price)
    const awayPrices = awayOutcomes.map(o => o.price)

    const homesummaries = calculateSummary(homePrices)
    const awaysummaries = calculateSummary(awayPrices)

    // testing, remove
    // besthome = besthome * 1.4
    // bestaway = bestaway * 1.4

    return { besthome, besthomeprovider, bestaway, bestawayprovider, besthomeLastUpdated, bestawayLastUpdated, lastUpdated, homesummaries, awaysummaries, homeOutcomes, awayOutcomes }
}

export function calculateSummary(numbers) {
    // Step 1: Compute the mean
    const mean = numbers.reduce((sum, value) => sum + value, 0) / numbers.length;
  
    // Step 2: Calculate the squared differences from the mean
    const squaredDifferences = numbers.map(value => Math.pow(value - mean, 2));
  
    // Step 3: Compute the mean of the squared differences
    const meanSquaredDifference = squaredDifferences.reduce((sum, value) => sum + value, 0) / numbers.length;
  
    // Step 4: Take the square root of this mean
    const standardDeviation = Math.sqrt(meanSquaredDifference);

    const count = numbers.length

    const coefficientOfVariation = standardDeviation / mean
  
    return {standardDeviation, coefficientOfVariation, mean, count};
  }

function DaySummary() {
    const params = useParams()
    const location = useLocation()

    // Get a specific query parameter
    const league = new URLSearchParams(location.search).get('league');
    console.log('league: ', league)

    const dataLoader = useSportLeagueDateData(league, params.date)
    console.log('game snapshots: ', dataLoader.gamesSnapshots.length)

    return (
        <table className="w-full overflow-scroll table-fixed border-collapse border border-slate-300">
            <thead className="bg-gray-100 sticky top-0 z-10">
                <tr>
                    <th className="sticky top-0 left-0 border border-slate-300 bg-gray-100 w-24 z-20"></th>
                    {
                        dataLoader.games.map((game, i) => {
                            return (
                                <th key={i} className="sticky top-0 z-20 border border-slate-300 w-80">
                                    <div className="flex flex-row">
                                        <label className={`w-1/3 ${game.homeWon ? '' : 'bg-green-300'}`}>{game.awayAbbrev}</label>
                                        <label className={`w-1/3 ${game.homeWon ? 'bg-green-300' : ''}`}>{game.homeAbbrev}</label>
                                        <div className="w-1/3" />
                                        {/* <img src={getTeamPicSrc(league, game.homeid, game.homeAbbrev)} className="h-12 w-12" />
                                        <img src={getTeamPicSrc(league, game.awayid, game.awayAbbrev)} className="h-12 w-12" /> */}
                                    </div>

                                </th>
                                // <th key={i} className="border border-slate-300 min-w-10">{game.homeTeam}<br />{game.awayTeam}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody className="sticky left-0">
                {
                    dataLoader.loading &&
                    (<tr>
                        <td>loading...</td>
                    </tr>)
                }
                {
                    dataLoader.gamesSnapshots.map((snap, i) => {
                        const oddsSnap = dataLoader.oddsSnapshots.find(o => o.id === snap.id)
                        return (
                            <tr key={i}>
                                <td className="sticky left-0 border border-slate-300 bg-white z-10 w-24">{snap.timeString}</td>
                                {
                                    dataLoader.games.map((game, i) => {
                                        const gsnap = snap.games.find(g => g.id === game.id)
                                        const odds = oddsSnap?.odds?.find(o => o.homeTeam === game.homeTeam && o.awayTeam === game.awayTeam)
                                        const gamebestodds = dataLoader.bestOdds[game.awayTeam + "@" + game.homeTeam]
                                        // console.log('gamebestodds: ', gamebestodds)
                                        const { besthome, besthomeprovider, bestaway, bestawayprovider, besthomeLastUpdated, bestawayLastUpdated, lastUpdated } = bestMLs(odds)

                                        const arbpct = arbitragePct(besthome, bestaway)
                                        const profPct = profitPct(arbpct)

                                        return (
                                            <td key={i} className="border border-slate-300 w-80">
                                                <label className="text-xs">{gsnap?.statusName === 'STATUS_SCHEDULED' ? '' : gsnap?.statusDetail}</label>
                                                <div className="flex flex-row" onClick={() => alert(`${snap.timeString}\n${game.awayAbbrev}: ${getAmericanOdds(bestaway)} (${bestawayprovider}, ${bestawayLastUpdated})\n${game.homeAbbrev}: ${getAmericanOdds(besthome)} (${besthomeprovider}, ${besthomeLastUpdated})`)}>
                                                    {/* <label className="w-24">{gsnap?.homeScore}</label>
                                                    <label className="w-24">{gsnap?.awayScore}</label> */}
                                                    <label className={`w-1/3 text-xs ${gamebestodds?.bestaway === bestaway && !game.homeWon ? 'bg-green-300' : ''}`}>{gsnap?.awayScore} {getAmericanOdds(bestaway)}</label>
                                                    <label className={`w-1/3 text-xs ${gamebestodds?.besthome === besthome && game.homeWon ? 'bg-green-300' : ''}`}>{gsnap?.homeScore} {getAmericanOdds(besthome)}</label>
                                                    <label className={`w-1/3 text-xs ${arbpct < 1 ? 'bg-blue-300' : ''}`}>{profPct}</label>
                                                    {/* <label className={`w-1/3 text-xs ${arbpct < 1 ? 'bg-blue-300' : ''}`}>{lastUpdated}</label> */}
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )

    // return (
    //     <div className="flex flex-col space-x-1">
    //         <label>{`Day summary for ${params.date}`}</label>
    //         {
    //             ncaabLoader.loading && <label>loading...</label>
    //         }
    //         {
    //             ncaabLoader.games.map((game, i) => {
    //                 const date = new Date(game.date)
    //                 const datestring = date.toLocaleDateString("en-US", { hour: 'numeric', minute: '2-digit' })
    //                 return (<label key={i}>{datestring}: {game.awayTeam} at {game.homeTeam}</label>)
    //             })
    //         }
    //         {/* {
    //             ncaabLoader.gamesSnapshots.map((snap, i) => {
    //                 return (<div key={i} className="flex flex-row space-x-1">
    //                     <label>{snap.timeString}</label>
    //                 </div>)
    //             })
    //         } */}
    //         {/* <label>{`NCAA snapshots: ${ncaabLoader.gamesSnapshots.length} games, ${ncaabLoader.oddsSnapshots.length} odds`}</label> */}
    //     </div>
    // )
}

export default DaySummary