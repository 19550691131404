import { onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { getDocRef, COLLECTIONS } from "./firebase"

function useUserDocument(userID) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [document, setDocument] = useState(null)

    useEffect(() => {
        if (!userID) {
            return
        }
        const reference = getDocRef(COLLECTIONS.users, userID)
        const unsubscribe = onSnapshot(reference, (d) => {
            if (d.exists()) {
                const a = d.data()
                a.id = d.id
                setDocument(a)
            } else {
                setDocument(null)
            }
            setLoading(false)
        })
        return () => unsubscribe()
    }, [userID])

    return {
        error,
        loading,
        document
    }
}

export default useUserDocument