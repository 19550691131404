import { React, useEffect, useState } from "react";
import { db } from "./firebase/firebase";
import { proportionToPct } from "./components/GameDataComponent";
import { doc, getDoc, where } from "firebase/firestore";
import { useParams, useSearchParams } from "react-router-dom";
import { centralTimeZoneDateString, getAmericanOdds, getExpectedReturnDecimalOdds } from "./PicksData";
import { MultiSelect } from "react-multi-select-component";
import useFirestoreQuery from "./firebase/useFirestoreQuery";
import { bestMLs } from "./components/DaySummary";
import TimeClock from "./components/TimeClock";
import GameOddsSnapshotComponent from "./components/GameOddsSnapshotComponent";
import NavBarTwo from "./components/NavBarTwo";

function LiveLooksPage() {
    const [dateString, setDate] = useState(null)
    const [searchParams] = useSearchParams()
    const [showDebug, setShowDebug] = useState(false)
    const [selectedGameID, setSelectedGameID] = useState()

    const gamesLoader = useFirestoreQuery('game_level_data', [where("date", "==", dateString)], null, dateString)
    const gameOddsLoader = useFirestoreQuery(`game_level_data/${selectedGameID}/odds`, [where("timestamp", ">", selectedGameStartDateObject())], sortByDate, selectedGameID)

    const winChangeThreshold = 0.05


    function sortByDate(a, b) {
        const aDate = a.timestamp.toDate()
        const bDate = b.timestamp.toDate()
        return aDate - bDate
    }

    // const [arbDetails, setArbDetails] = useState(null)
    const [selectedSportsbooks, setSelectedSportsbooks] = useState([])
    const [sportsbookOptions, setSportsbookOptions] = useState([])

    useEffect(() => {
        if (searchParams.has('date')) {
            setDate(searchParams.get('date'))
        } else {
            const today = new Date()
            setDate(centralTimeZoneDateString(today))
        }

        async function getSportsbooksByState() {
            const docRef = doc(db, 'variables', 'sportsbooksByState')
            const docSnap = await getDoc(docRef)
            console.log('sportsbooks by state: ', docSnap.data())

            const sportsbooks = Object.keys(docSnap.data().bySportsbook)
            sportsbooks.sort((a, b) => {
                return docSnap.data().bySportsbook[b].length - docSnap.data().bySportsbook[a].length
            })
            console.log('sorted sportsbooks: ', sportsbooks)
            setSportsbookOptions(sportsbooks.map(b => {
                return { label: b, value: b }
            }))
        }
        getSportsbooksByState()
    }, [searchParams])

    if (gamesLoader.loading || sportsbookOptions.length === 0) {
        return <>loading...</>
    }

    function selectedGameData() {
        return gamesLoader.results.find(g => g.id === selectedGameID)
    }

    function selectedGameStartDateObject() {
        const selGame = selectedGameData()
        if (!selGame) {
            return null
        } else {
            return new Date(selectedGameData()?.startTimestamp)
        }
    }

    const headerStyle = "sticky top-0 bg-gray-100"

    const lastupdateddateoptions = { hour: 'numeric', minute: '2-digit' };
    const lastupdatedtimeoptions = { hour: 'numeric', minute: '2-digit', second: '2-digit' };
    const gametimeoptions = { hour: 'numeric', minute: '2-digit' };

    function SportsbookSelector() {
        return <div className="sticky top-0 h-10 z-20">
            <MultiSelect
                options={sportsbookOptions}
                value={selectedSportsbooks}
                onChange={setSelectedSportsbooks}
                labelledBy="Select Sportsbooks"
            />
        </div>
    }

    function getWinProbabilityEventsDateDescending(game) {
        const gameEvents = []
        game.winprobability.forEach(wp => {
            const play = game.plays.find(p => p.id === wp.playId)
            if (play) {
                const d = new Date(play.wallclock)
                const newEvent = {
                    eventType: 'game',
                    dateObj: d,
                    time: d.toLocaleDateString("en-US", lastupdatedtimeoptions),
                    eventText: play.text ?? "-",
                    homeWin: wp.homeWinPercentage,
                    awayWin: 1 - wp.homeWinPercentage
                }
                if (gameEvents.length > 0) {
                    const lastEvent = gameEvents[gameEvents.length - 1]
                    newEvent.projectionChange = Math.abs(newEvent.homeWin - lastEvent.homeWin)
                }
                gameEvents.push(newEvent)
            }
        })

        gameEvents.sort((a, b) => {
            return b.dateObj - a.dateObj
        })

        return gameEvents
    }

    function getLastBigGameEvent(game) {
        const wpEvents = getWinProbabilityEventsDateDescending(game)
        return wpEvents.find(e => e.projectionChange > winChangeThreshold)
    }

    function gameEventsArray() {
        const game = selectedGameData()
        const gameEvents = getWinProbabilityEventsDateDescending(game)

        const oddsEvents = []
        gameOddsLoader.results
            .forEach(o => {
                const d = o.timestamp.toDate()
                const latestGameEvent = gameEvents.find(e => e.dateObj < d)
                const lastBigEvent = gameEvents.find(e => e.dateObj < d && e.projectionChange > winChangeThreshold)
                const oddsSince = lastBigEvent?.dateObj
                const bestOdds = bestMLs(o, game.oddsApiHomeTeam, game.oddsApiAwayTeam, oddsSince)
                const homeodds = bestOdds.homesummaries?.mean
                const awayodds = bestOdds.awaysummaries?.mean
                const oddsEvent = { eventType: 'odds', dateObj: d, time: d.toLocaleDateString("en-US", lastupdatedtimeoptions), oddsSinceTime: oddsSince?.toLocaleTimeString("en-US", lastupdatedtimeoptions), eventText: "-", homeOdds: getAmericanOdds(homeodds), awayOdds: getAmericanOdds(awayodds) }

                if (latestGameEvent) {
                    oddsEvent.lastHomeWinPct = latestGameEvent.homeWin
                    oddsEvent.lastAwayWinPct = latestGameEvent.awayWin
                    oddsEvent.homeReturn = getExpectedReturnDecimalOdds(latestGameEvent.homeWin, bestOdds.homesummaries.mean)
                    oddsEvent.awayReturn = getExpectedReturnDecimalOdds(latestGameEvent.awayWin, bestOdds.awaysummaries.mean)
                }

                oddsEvent.numHomeOdds = bestOdds.homesummaries.count
                oddsEvent.numAwayOdds = bestOdds.awaysummaries.count
                oddsEvent.stdDevHomeOdds = bestOdds.homesummaries.standardDeviation
                oddsEvent.stdDevAwayOdds = bestOdds.awaysummaries.standardDeviation
                oddsEvent.cvHomeOdds = bestOdds.homesummaries.coefficientOfVariation
                oddsEvent.cvAwayOdds = bestOdds.awaysummaries.coefficientOfVariation
                oddsEvents.push(oddsEvent)
            })

        const events = [gameEvents, oddsEvents].flat()

        events.sort((a, b) => {
            return b.dateObj - a.dateObj
        })
        return events
    }

    function getColor(returnProportion) {
        if (returnProportion > 0.3) {
            return "bg-green-500"
        } else if (returnProportion > 0.2) {
            return "bg-green-400"
        } else if (returnProportion > 0.1) {
            return "bg-green-300"
        } else if (returnProportion > 0.0) {
            return "bg-green-200"
        } else {
            return "bg-white"
        }
    }

    if (selectedGameID) {
        const game = selectedGameData()
        const lastbigone = getLastBigGameEvent(game)
        const oddsdata = bestMLs(game.latestOdds, null, null, lastbigone?.dateObj)
        return (
            <div>
                <div className="flex flex-row ml-2 mt-3 space-x-2 items-center">
                    <button className="px-4 py-1 border border-neutral-800 rounded-full text-sm" onClick={() => setSelectedGameID(null)}>Close</button>
                    <label className="italic"></label>
                </div>

                {/* <div>selected game id: {selectedGameID}</div> */}
                {/* <div>{gameOddsLoader.results.length} odds snapshots</div> */}
                <GameOddsSnapshotComponent awayTeam={game.awayTeamObject} homeTeam={game.homeTeamObject} oddsInfo={oddsdata} />
                {/* <table className="w-full table-fixed border">
                    <thead className="sticky top-0">
                        <tr className="bg-gray-200">
                            <th>Time</th>
                            <th>{selectedGameData().awayTeam}</th>
                            <th>{selectedGameData().homeTeam}</th>
                            <th>debug</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            gameEventsArray().map((e, i) => {
                                if (e.eventType === 'game') {
                                    return (
                                        <tr key={i} className={e.projectionChange > winChangeThreshold ? "bg-blue-400" : "bg-blue-100"}>
                                            <td>{e.time}</td>
                                            <td>{proportionToPct(e.awayWin, 1)}</td>
                                            <td>{proportionToPct(e.homeWin, 1)}</td>
                                            <td>{proportionToPct(e.projectionChange, 1)}</td>
                                        </tr>
                                    )
                                } else {
                                    return (
                                        <tr key={i} className="bg-white">
                                            <td>{e.time}</td>
                                            <td className={getColor(e.awayReturn)}>{e.awayOdds} ({e.numAwayOdds}, {proportionToPct(e.cvAwayOdds)}) : {proportionToPct(e.awayReturn)}</td>
                                            <td className={getColor(e.homeReturn)}>{e.homeOdds} ({e.numHomeOdds}, {proportionToPct(e.cvHomeOdds)}) : {proportionToPct(e.homeReturn)}</td>
                                            <td>{e.oddsSinceTime} {proportionToPct(e.lastAwayWinPct)} @ {proportionToPct(e.lastHomeWinPct)}</td>
                                        </tr>
                                    )
                                }
                            })
                        }

                    </tbody>
                </table> */}
            </div>
        )
    }

    function getSituationSummary(game) {
        let status = ""
        if (game.status === "STATUS_IN_PROGRESS") {
            status = "In Progress"
        } else if (game.status === "STATUS_HALFTIME") {
            status = "Halftime"
        } else if (game.status === "STATUS_SCHEDULED") {
            status = "Scheduled"
        } else if (game.status === "STATUS_FINAL") {
            status = "Final"
        } else {
            status = "Unknown"
        }

        let lastPlay = null

        if (game.plays.length > 0) {
            lastPlay = game.plays[game.plays.length - 1]
        }

        const homeScore = lastPlay?.homeScore ?? ""
        const awayScore = lastPlay?.awayScore ?? ""

        const lastPlayText = lastPlay?.text
        let gameProgress = ""
        if (lastPlay) {
            if (lastPlay.period.type) {
                gameProgress = lastPlay.period.type + " " + lastPlay.period.displayValue
            } else if (lastPlay.clock.displayValue) {
                gameProgress = lastPlay.clock.displayValue + " " + lastPlay.period.displayValue
            } else {
                gameProgress = lastPlay.period.displayValue
            }
        }
        // const gameProgress = lastPlay ? (lastPlay.period.type + " " + lastPlay.period.displayValue) : ""

        let homeWinProb = parseFloat(game.predictor?.homeTeam?.gameProjection) / 100
        let awayWinProb = parseFloat(game.predictor?.awayTeam?.gameProjection) / 100

        if (game.winprobability.length === 0) {
            return { status, gameProgress, lastPlayText, homeScore, awayScore, homeWinProb, awayWinProb, }
        }

        const wp = game.winprobability[game.winprobability.length - 1]
        homeWinProb = wp.homeWinPercentage
        awayWinProb = 1 - wp.homeWinPercentage
        let latestWinProbPlay = game.plays.find(p => p.id === wp.playId)
        if (!latestWinProbPlay) {
            latestWinProbPlay = game.plays[game.plays.length - 1]
        }
        const winprobLastUpdated = new Date(latestWinProbPlay?.wallclock)
        // winprobs = `${proportionToPct(awayWin)} / ${proportionToPct(homeWin)}`

        return { status, gameProgress, lastPlayText, homeScore, awayScore, homeWinProb, awayWinProb, winprobLastUpdated }
    }

    const statusHeight = "h-5"
    const teamLineHeight = "h-6"
    const borderColor = "border-neutral-300"

    let oddsLastUpdated = "-"
    if (gamesLoader.results.length > 0) {
        const odate = gamesLoader.results[0].oddsUpdatedAt?.toDate()
        oddsLastUpdated = odate?.toLocaleTimeString("en-US", lastupdatedtimeoptions)
    }

    function TeamScoreComponent({ color, teamObject, score }) {
        return <div className={`flex flex-row items-center justify-between ${teamLineHeight} ${color} px-1`}>
            <div className="flex flex-row space-x-1 items-center h-full">
                <img src={teamObject?.logo} className={`h-full`} />
                <label className="md:hidden">{teamObject?.abbreviation}</label>
                <label className="hidden md:flex">{teamObject?.displayName}</label>
            </div>
            <label className="flex items-center">{score}</label>
        </div>
    }

    function DataComponent({ awayText, awayColor, homeText, homeColor }) {
        return <td className={`m-0 p-0`}>
            <div className="flex flex-col">
                <div className={statusHeight + ' text-sm'}></div>
                <div className={`${teamLineHeight} ${awayColor} flex justify-center items-center`}>{awayText}</div>
                <div className={`${teamLineHeight} ${homeColor} flex justify-center items-center`}>{homeText}</div>
            </div>
        </td>
    }


    return (
        <div className="flex flex-col">
            <NavBarTwo />
            {/* <TimeClock />
            <label>odds from: {oddsLastUpdated}</label>
            <button onClick={() => setShowDebug(!showDebug)}>toggle debug</button> */}
            {/* <SportsbookSelector /> */}

            <table className={"m-0 md:m-4 flex-1 table-fixed overflow-scroll border-collapse md:border " + borderColor}>
                <thead className="bg-gray-100 sticky top-12 z-10">
                    <tr>
                        <th className="sticky left-0 bg-gray-100 w-1/3 z-20"></th>
                        <th className={headerStyle}>Odds</th>
                        <th className={headerStyle}>Win%</th>
                        <th className={headerStyle}>EV</th>
                        {
                            showDebug &&
                            <th className={headerStyle}>Debug</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        gamesLoader.results.filter(g => g.status === 'STATUS_FINAL' || g.latestOdds)
                        .sort((a, b) => {
                            const aDate = new Date(a.startTimestamp)
                            const bDate = new Date(b.startTimestamp)
                            if (a.status === b.status) {
                                return aDate - bDate
                            } else if (a.status === "STATUS_IN_PROGRESS" || a.status === "STATUS_HALFTIME") {
                                return -1
                            } else if (b.status === "STATUS_IN_PROGRESS" || b.status === "STATUS_HALFTIME") {
                                return 1
                            } else if (a.status === "STATUS_SCHEDULED") {
                                return -1
                            } else if (b.status === "STATUS_SCHEDULED") {
                                return 1
                            } else {
                                return aDate - bDate
                            }
                        }).map((game, i) => {
                            const gameTime = new Date(game.startTimestamp)

                            // const wpEvents = getWinProbabilityEventsDateDescending(game)
                            const lastBigEvent = getLastBigGameEvent(game)

                            const situationTexts = getSituationSummary(game)
                            // const oddsInfo = bestMLs(game.latestOdds, null, null, situationTexts.winprobLastUpdated) // TODO: add minimum timestamp threshold for odds after game events
                            const oddsInfo = bestMLs(game.latestOdds, null, null, lastBigEvent?.dateObj)
                            // const awayOdds = oddsInfo.bestaway
                            // const homeOdds = oddsInfo.besthome
                            const awayOdds = oddsInfo.awaysummaries?.mean
                            const homeOdds = oddsInfo.homesummaries?.mean
                            // oddsInfo.awaysummaries?.mean
                            const awayreturn = getExpectedReturnDecimalOdds(situationTexts.awayWinProb, awayOdds)
                            const homereturn = getExpectedReturnDecimalOdds(situationTexts.homeWinProb, homeOdds)
                            const awaycol = game.status === 'STATUS_FINAL' ? 'bg-white' : getColor(awayreturn)
                            const homecol = game.status === 'STATUS_FINAL' ? 'bg-white' : getColor(homereturn)

                            const sortedHomeOutcomes = oddsInfo.homeOutcomes?.sort((a, b) => {
                                return a.price > b.price ? -1 : (b.price > a.price ? 1 : 0)
                            }) ?? []

                            return (
                                <tr className={"border-b text-sm " + borderColor} key={game.id} onClick={() => setSelectedGameID(game.id)}>
                                    <td className="flex flex-col m-0 p-0">
                                        <label className={statusHeight + " text-sm pl-1"}>{situationTexts.status === "In Progress" ? (situationTexts.gameProgress ?? situationTexts.status) : (situationTexts.status === "Scheduled" ? gameTime.toLocaleTimeString("en-US", gametimeoptions) : situationTexts.status)}</label>
                                        <TeamScoreComponent color={awaycol} teamObject={game.awayTeamObject} score={situationTexts.awayScore} />
                                        <TeamScoreComponent color={homecol} teamObject={game.homeTeamObject} score={situationTexts.homeScore} />
                                    </td>
                                    {
                                        (game.status === 'STATUS_IN_PROGRESS' || game.status === 'STATUS_SCHEDULED' || game.status === 'STATUS_HALFTIME') &&
                                        <>
                                            {/* <td className={`border border-slate-300`}>{getAmericanOdds(oddsInfo.awaysummaries?.mean)} ({oddsInfo.awaysummaries?.count}, {proportionToPct(oddsInfo.awaysummaries?.coefficientOfVariation)})<br/>{getAmericanOdds(oddsInfo.homesummaries?.mean)} ({oddsInfo.homesummaries?.count}, {proportionToPct(oddsInfo.homesummaries?.coefficientOfVariation)})</td> */}
                                            <DataComponent awayText={getAmericanOdds(awayOdds)} awayColor={awaycol} homeText={getAmericanOdds(homeOdds)} homeColor={homecol} />
                                            <DataComponent awayText={proportionToPct(situationTexts.awayWinProb, 1)} awayColor={awaycol} homeText={proportionToPct(situationTexts.homeWinProb, 1)} homeColor={homecol} />
                                            <DataComponent awayText={proportionToPct(awayreturn, 1, true)} awayColor={awaycol} homeText={proportionToPct(homereturn, 1, true)} homeColor={homecol} />
                                            {
                                                showDebug &&
                                                <td className={``}>
                                                    <div className="flex flex-col">
                                                        {
                                                            sortedHomeOutcomes.map(o => {
                                                                const date = new Date(o.last_update)
                                                                return <label key={o.provider}>{getAmericanOdds(o.price)}, {o.provider}, {date.toLocaleTimeString("en-US", lastupdatedtimeoptions)}</label>
                                                            })
                                                        }
                                                    </div>
                                                </td>
                                            }
                                            {/* <td className={`border border-slate-300`}>as of {situationTexts.winprobLastUpdated?.toLocaleTimeString("en-US", lastupdatedtimeoptions)}<br/>{proportionToPct(situationTexts.awayWinProb)}<br/>{proportionToPct(situationTexts.homeWinProb)}</td> */}
                                            {/* <td className={`border border-slate-300 ${getColor(homereturn)}`}>{proportionToPct(awayreturn, 2)}<br/>{proportionToPct(homereturn, 2)}</td> */}
                                        </>
                                    }
                                    {/* {
                                        game.status !== 'STATUS_IN_PROGRESS' &&
                                        <>
                                            <td className="border border-slate-300"></td>
                                            <td className="border border-slate-300"></td>
                                            <td className="border border-slate-300"></td>
                                        </>
                                    } */}


                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >

    )


}

export default LiveLooksPage;