import { getExpectedReturnDecimalOdds, getPercentageText, getAmericanOdds } from "../PicksData"

function bgColor(expReturn) {
    if (expReturn < 0) {
        return null
        // return "bg-gray-100"
    } else if (expReturn < 0.05) {
        return "bg-green-500"
    } else if (expReturn < 0.1) {
        return "bg-green-600"
    } else if (expReturn < 0.2) {
        return "bg-green-700"
    } else {
        return "bg-green-800"
    }
}

function getTeamPicSrc(league, teamid, teamabbrev) {
    if (league === 'college-football' || league === 'mens-college-basketball') {
        return `https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa/500/${teamid}.png&h=100&w=100`
    } else {
        return `https://a.espncdn.com/combiner/i?img=/i/teamlogos/${league}/500/${teamabbrev.toLowerCase()}.png&scale=crop&cquality=40&location=origin&w=80&h=80`
    }
}

function individualArbPct(decimalOdds) {
    if (isNaN(decimalOdds)) {
        return null
    } else {
        return (1 / decimalOdds)
    }
}

function arbitragePct(decimalOddsA, decimalOddsB) {
    if (isNaN(decimalOddsA) || isNaN(decimalOddsB)) {
        return null
    } else {
        // return Math.round((1 / decimalOddsA) * 100 + (1 / decimalOddsB) * 100)
        // return ((1 / decimalOddsA) * 100 + (1 / decimalOddsB) * 100).toFixed(2) + "%"
        // return (1 / decimalOddsA) + (1 / decimalOddsB)
        return individualArbPct(decimalOddsA) + individualArbPct(decimalOddsB)
    }
}

function profitPct(arbPct) {
    if (isNaN(arbPct)) {
        return null
    } else {
        return (100 / arbPct - 100).toFixed(2) + "%"
        // return (arbPct - 100).toFixed(2) + "%"
    }
}

function stakePct(individualArbPct, arbPct) {
    if (isNaN(individualArbPct) || isNaN(arbPct)) {
        return null
    } else {
        return (individualArbPct / arbPct * 100).toFixed(2) + "%"
    }
}

function GameTableRow({ onClick, gamedata, bestMarketOutcomeOdds, worstMarketOutcomeOdds, americanStyleOdds, showBetaFeatures, showArb }) {
    function priceText(decimalPrice) {
        if (isNaN(decimalPrice)) {
            return null
        } else if (americanStyleOdds) {
            return getAmericanOdds(decimalPrice)
        } else {
            return decimalPrice
        }
    }

    function SourcedDataButton({ primaryText, secondaryText, organization, bgColor, textColor }) {
        return (
            <div className={`flex h-10 w-14 rounded relative ${bgColor ?? 'bg-white border '} ${textColor ?? 'text-black'}`}>
                <div className="flex flex-col m-auto items-center">
                    <label className="text-sm font-semibold">{primaryText}</label>
                    {
                        secondaryText &&
                        <label className={`text-xs ${textColor ?? 'text-black'} text-opacity-60`}>{secondaryText}</label>
                    }
                </div>
                <img src={`/images/${organization?.toLowerCase()}_square.png`} className="w-3 h-3 absolute bottom-0 right-0 rounded-tl-sm rounded-br-sm" />
            </div>
        )
    }

    function TeamData(isHome) {
        const bestH2HOdds = bestMarketOutcomeOdds['h2h::' + (isHome ? gamedata?.oddsApiHomeTeam : gamedata?.oddsApiAwayTeam)]
        const bestSpreadsOdds = bestMarketOutcomeOdds['spreads::' + (isHome ? gamedata?.oddsApiHomeTeam : gamedata?.oddsApiAwayTeam)]
        const bestTotalsOdds = bestMarketOutcomeOdds['totals::' + (isHome ? 'Under' : 'Over')]

        const worstH2HOdds = worstMarketOutcomeOdds['h2h::' + (isHome ? gamedata?.oddsApiHomeTeam : gamedata?.oddsApiAwayTeam)]
        const worstSpreadsOdds = worstMarketOutcomeOdds['spreads::' + (isHome ? gamedata?.oddsApiHomeTeam : gamedata?.oddsApiAwayTeam)]

        const espnProj = gamedata?.predictions?.find(p => p.provider === 'espn')
        const numberfireProj = gamedata?.predictions?.find(p => p.provider === 'numberfire')
        // console.log('espnproj: ', espnProj, 'numberfireProj: ', numberfireProj)
        const espnWinPct = isHome ? espnProj?.homeWinPct : espnProj?.awayWinPct
        const nfWinPct = isHome ? numberfireProj?.homeWinPct : numberfireProj?.awayWinPct
        const espnExpReturn = getExpectedReturnDecimalOdds(espnWinPct, bestH2HOdds?.price)
        const nfExpReturn = getExpectedReturnDecimalOdds(nfWinPct, bestH2HOdds?.price)

        const arbml = isHome ? gamedata?.bestLines?.besthome : gamedata?.bestLines?.bestaway
        const arbProvider = isHome ? gamedata?.bestLines?.besthomeprovider : gamedata?.bestLines?.bestawayprovider
        const arbLastUpdated = isHome ? gamedata?.bestLines?.besthomeLastUpdated : gamedata?.bestLines?.bestawayLastUpdated
        const arbstakepct = isHome ? gamedata?.stakePctHome : gamedata?.stakePctAway

        return { bestH2HOdds, bestSpreadsOdds, bestTotalsOdds, espnWinPct, nfWinPct, espnExpReturn, nfExpReturn, arbml, arbProvider, arbLastUpdated, arbstakepct }

        function getDiffPctText() {
            if (bestH2HOdds?.price && worstH2HOdds?.price) {
                return getPercentageText(((bestH2HOdds?.price - worstH2HOdds?.price) / worstH2HOdds?.price), true)
            } else {
                return null
            }
        }



        return (
            <div className="flex flex-col space-y-1 sm:space-y-0 sm:flex-row sm:justify-between w-full mt-2">
                {/* <div className="flex space-y-0 flex-row justify-between w-full mt-2"> */}
                <div className="flex flex-row items-center flex-auto ml-2">
                    <img src={getTeamPicSrc(gamedata.league, isHome ? gamedata.homeid : gamedata.awayid, isHome ? gamedata.homeAbbrev : gamedata.awayAbbrev)} onError={i => i.target.style.opacity = "0"} className="h-6 w-6" />
                    <label className="pl-2 font-semibold text-sm flex flex-auto truncate min-w-0">{isHome ? gamedata.homeTeam : gamedata.awayTeam}</label>
                    {/* <label className="pl-2 font-semibold text-sm flex flex-auto truncate min-w-0">{isHome ? gamedata.homeAbbrev : gamedata.awayAbbrev}</label> */}
                </div>

                <div className="flex flex-row items-center ml-1 space-x-1 sm:mr-2 lg:space-x-3">
                    {/* <div className="flex flex-row items-center ml-1 space-x-1"> */}
                    <div className={`text-sm items-center bg-gray-700 rounded text-white w-8 text-center ${gamedata.statusName === 'STATUS_SCHEDULED' ? 'hidden' : ''}`}>{isHome ? gamedata.homeScore : gamedata.awayScore}</div>

                    <SourcedDataButton primaryText={(bestSpreadsOdds?.point > 0 ? '+' : '') + bestSpreadsOdds?.point} secondaryText={priceText(bestSpreadsOdds?.price)} organization={bestSpreadsOdds?.organizationKey} />
                    <SourcedDataButton primaryText={(bestTotalsOdds?.name === 'Over' ? 'o' : 'u') + (bestTotalsOdds?.point)} secondaryText={priceText(bestTotalsOdds?.price)} organization={bestTotalsOdds?.organizationKey} />
                    <SourcedDataButton primaryText={priceText(bestH2HOdds?.price)} secondaryText={showBetaFeatures && getDiffPctText()} organization={bestH2HOdds?.organizationKey} />

                    {
                        gamedata.predictions.map(p => {
                            if (gamedata.statusName !== 'STATUS_SCHEDULED') {
                                return <div key={p.provider} className="h-10 w-14" />
                            } else {
                                const winPct = isHome ? p.homeWinPct : p.awayWinPct
                                const expReturn = getExpectedReturnDecimalOdds(winPct, bestH2HOdds?.price)
                                return <SourcedDataButton key={p.provider} primaryText={getPercentageText(expReturn, true)} secondaryText={getPercentageText(winPct, false)} organization={p.provider} bgColor={bgColor(expReturn)} textColor={expReturn > 0 ? 'text-white' : 'text-black'} />
                            }

                        })
                    }
                </div>
            </div>
        )
    }

    function ValueMetricsDataLine() {
        const home_bestH2HOdds = bestMarketOutcomeOdds['h2h::' + (gamedata?.oddsApiHomeTeam)]
        const home_bestSpreadsOdds = bestMarketOutcomeOdds['spreads::' + (gamedata?.oddsApiHomeTeam)]
        const under_bestTotalsOdds = bestMarketOutcomeOdds['totals::' + 'Under']

        const away_bestH2HOdds = bestMarketOutcomeOdds['h2h::' + (gamedata?.oddsApiAwayTeam)]
        const away_bestSpreadsOdds = bestMarketOutcomeOdds['spreads::' + (gamedata?.oddsApiAwayTeam)]
        const over_bestTotalsOdds = bestMarketOutcomeOdds['totals::' + 'Over']

        // const spreadArbPct = arbitragePct(home_bestSpreadsOdds?.price, away_bestSpreadsOdds?.price)
        // const totalArbPct = arbitragePct(over_bestTotalsOdds?.price, under_bestTotalsOdds?.price)
        // const h2hArbPct = arbitragePct(home_bestH2HOdds?.price, away_bestH2HOdds?.price)

        const spreadPrices = { home: home_bestSpreadsOdds?.price, away: away_bestSpreadsOdds?.price }
        const totalPrices = { away: over_bestTotalsOdds?.price, home: under_bestTotalsOdds?.price }
        const h2hPrices = { home: home_bestH2HOdds?.price, away: away_bestH2HOdds?.price }

        return (
            <div className="flex flex-row justify-end w-full">
                <div className="flex flex-row items-center text-center space-x-1 lg:space-x-3">
                    {
                        [spreadPrices, totalPrices, h2hPrices].map((prices, i) => {
                            const arbPct = arbitragePct(prices.home, prices.away)
                            if (prices === h2hPrices) {
                                return <label className={`w-14 text-sm ${arbPct < 1 ? 'font-bold' : 'font-regular'}`}>{profitPct(arbPct)}</label>
                            } else {
                                return <div className="w-14" />
                            }
                            // return <div key={i} className={`w-14 text-xs`}>{getPercentageText(arbPct, false)}<br />{profitPct(arbPct)}<br />{'away: ' + stakePct(individualArbPct(prices.away), arbPct)}<br />{'home: ' + stakePct(individualArbPct(prices.home), arbPct)}</div>
                        })
                    }
                    {
                        gamedata.predictions.map(p => {
                            return <div key={p.id + p.provider} className="w-14" />
                        })
                    }
                </div>
            </div>
        )
    }



    function dateTimeText() {
        // check if date is in today, if so, just return timeText, otherwise return dateText
        const now = new Date()
        const gamedate = new Date(gamedata.rawdate)
        if (gamedate.getDate() === now.getDate() &&
            gamedate.getMonth() === now.getMonth() &&
            gamedate.getFullYear() === now.getFullYear()) {
            return gamedata.timeText
        } else {
            return gamedata.dateText
        }
    }

    function statusText() {
        if (gamedata.statusName === 'STATUS_SCHEDULED') {
            return dateTimeText()
        } else {
            return gamedata.statusDetail
        }
    }

    function OddsCell({ primaryText, secondaryText, organization }) {
        return (
            <div className="flex flex-row w-full grow h-10">
                <img src={`/images/${organization?.toLowerCase()}_square.png`} className="max-w-10 h-full" />
                <div className="flex flex-col grow justify-center">
                    <label className="text-md font-bold text-center">{primaryText}</label>
                    {
                        secondaryText &&
                        <label className="text-xs text-center">{secondaryText}</label>
                    }
                </div>
            </div>
        )
    }

    const hometeamdata = TeamData(true)
    const awayteamdata = TeamData(false)

    return <tr className="border-b border-black" onClick={onClick}>
        <td className="sticky left-0 z-10 bg-white bg-opacity-95">
            <div className="flex flex-col ml-2">
                <label className="text-sm h-8">{statusText()}</label>
                <div className="flex flex-row items-center flex-auto h-10">
                    <img src={getTeamPicSrc(gamedata.league, gamedata.awayid, gamedata.awayAbbrev)} onError={i => i.target.style.opacity = "0"} className="h-8 w-8" />
                    <label className="inline md:hidden pl-2 font-semibold text-sm flex flex-auto truncate min-w-0">{gamedata.awayAbbrev}</label>
                    <label className="hidden md:inline pl-2 font-semibold text-sm flex flex-auto truncate min-w-0">{gamedata.awayTeam}</label>
                </div>
                <div className="flex flex-row items-center flex-auto h-10">
                    <img src={getTeamPicSrc(gamedata.league, gamedata.homeid, gamedata.homeAbbrev)} onError={i => i.target.style.opacity = "0"} className="h-8 w-8" />
                    <label className="inline md:hidden pl-2 font-semibold text-sm flex flex-auto truncate min-w-0">{gamedata.homeAbbrev}</label>
                    <label className="hidden md:inline pl-2 font-semibold text-sm flex flex-auto truncate min-w-0">{gamedata.homeTeam}</label>
                </div>
                {/* <label className="text-sm h-12">{gamedata.awayAbbrev}</label> */}
                {/* <label className="text-sm h-12">{gamedata.homeAbbrev}</label> */}
            </div>
        </td>
        {
            !showArb && <>
                <td className="flex flex-row bg-white">
                    {
                        gamedata.statusName !== "STATUS_FINAL" && <>
                            <div className="flex flex-col grow">
                                <div className="h-8" />
                                {
                                    [awayteamdata, hometeamdata].map((teamdata, i) => {
                                        return <div key={i}>
                                            <OddsCell primaryText={(teamdata.bestSpreadsOdds?.point > 0 ? '+' : '') + teamdata.bestSpreadsOdds?.point} secondaryText={priceText(teamdata.bestSpreadsOdds?.price)} organization={teamdata.bestSpreadsOdds?.organizationKey} />
                                        </div>
                                    })
                                }
                            </div>
                            <div className="flex flex-col grow">
                                <div className="h-8" />
                                {
                                    [awayteamdata, hometeamdata].map((teamdata, i) => {
                                        return <div key={i}>
                                            <OddsCell primaryText={(teamdata.bestTotalsOdds?.name === 'Over' ? 'o' : 'u') + (teamdata.bestTotalsOdds?.point)} secondaryText={priceText(teamdata.bestTotalsOdds?.price)} organization={teamdata.bestTotalsOdds?.organizationKey} />
                                        </div>
                                    })
                                }
                            </div>
                            <div className="flex flex-col grow">
                                <div className="h-8" />
                                {
                                    [awayteamdata, hometeamdata].map((teamdata, i) => {
                                        return <div key={i}>
                                            <OddsCell primaryText={priceText(teamdata.bestH2HOdds?.price)} secondaryText={null} organization={teamdata.bestH2HOdds?.organizationKey} />
                                        </div>
                                    })
                                }
                            </div>
                        </>
                    }
                </td>
                <td className="bg-white bg-opacity-70 border-l border-black">
                    {
                        gamedata.statusName === "STATUS_SCHEDULED" && <div className="flex flex-row">
                            <div className="flex flex-col grow w-1/4">
                                <div className="h-8 text-center text-sm font-medium">Win%</div>
                                {
                                    [awayteamdata, hometeamdata].map((teamdata, i) => {
                                        return <div key={i} className="h-10 text-center flex items-center m-auto">
                                            <label className="justify-center">{getPercentageText(teamdata.espnWinPct, false)}</label>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="flex flex-col grow border-r border-black w-1/4">
                                <div className="h-8 text-center text-sm font-medium">EV</div>
                                {
                                    [awayteamdata, hometeamdata].map((teamdata, i) => {
                                        return <div key={i} className={`h-10 text-center flex justify-center items-center w-full ${bgColor(teamdata.espnExpReturn) ?? ''} ${teamdata.espnExpReturn > 0 ? 'text-white font-semibold' : 'text-black'}`}>
                                            <label>{getPercentageText(teamdata.espnExpReturn, true)}</label>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="flex flex-col grow w-1/4">
                                <div className="h-8 text-center text-sm font-medium">Win%</div>
                                {
                                    [awayteamdata, hometeamdata].map((teamdata, i) => {
                                        return <div key={i} className="h-10 text-center flex items-center m-auto">
                                            <label>{getPercentageText(teamdata.nfWinPct, false)}</label>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="flex flex-col grow w-1/4">
                                <div className="h-8 text-center text-sm font-medium">EV</div>
                                {
                                    [awayteamdata, hometeamdata].map((teamdata, i) => {
                                        return <div key={i} className={`h-10 text-center flex justify-center items-center w-full ${bgColor(teamdata.nfExpReturn) ?? ''} ${teamdata.nfExpReturn > 0 ? 'text-white font-semibold' : 'text-black'}`}>
                                            <label>{getPercentageText(teamdata.nfExpReturn, true)}</label>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }
                </td>
            </>
        }
        {
            showArb &&
            <td className="bg-white bg-opacity-70  border-l border-black">
            {
                gamedata.arbpct < 1 &&
                <div className="flex flex-row">
                    <div className="flex flex-col grow w-1/3">
                        <div className="h-8 text-center text-sm font-medium"></div>
                        {
                            [awayteamdata, hometeamdata].map((teamdata, i) => {
                                const lastupdate = new Date(teamdata.arbLastUpdated)
                                const dateoptions = { hour: 'numeric', minute: '2-digit', second: '2-digit' };

                                return <div key={i} >
                                    <OddsCell primaryText={priceText(teamdata.arbml)} secondaryText={lastupdate.toLocaleTimeString("en-US", dateoptions)} organization={teamdata.arbProvider} />
                                    {/* <label className="justify-center">{teamdata.bo}</label> */}
                                </div>
                            })
                        }
                    </div>
                    <div className="flex flex-col grow w-1/3">
                        <div className="h-8 text-center text-sm font-medium"></div>
                        {
                            [awayteamdata, hometeamdata].map((teamdata, i) => {
                                return <div key={i} className="h-10 text-center flex justify-center items-center w-full" >
                                    <label className="justify-center">{teamdata.arbstakepct}</label>
                                </div>
                            })
                        }
                    </div>
                    <div className="flex flex-col grow w-1/3">
                        <div className="h-8 text-center text-sm font-medium"></div>
                        <div className={`h-20 text-center flex justify-center items-center w-full`}>
                            {/* <label>{getPercentageText(gamedata.arbpct, false)}</label> */}
                            <label>{gamedata.profPct}</label>
                        </div>
                    </div>
                </div>
            }

        </td>
        }
        
    </tr>
}

export default GameTableRow
export { bgColor, getTeamPicSrc, arbitragePct, profitPct, stakePct, individualArbPct }