import { doc, updateDoc } from "firebase/firestore"
import { db } from "../firebase/firebase"

class User {
    constructor(id, email, createdAt, invoiceStatus, stripeCustomerEmail, subscription, stateLocation, sportsbooks, currentDefaultSport, previewEndsAt) {
        this.id = id
        this.email = email
        this.createdAt = createdAt
        this.invoiceStatus = invoiceStatus
        this.stripeCustomerEmail = stripeCustomerEmail
        this.subscription = subscription
        this.stateLocation = stateLocation
        this.sportsbooks = sportsbooks
        this.currentDefaultSport = currentDefaultSport
        this.previewEndsAt = previewEndsAt
    }

    async updateStateAndSportsbooks(state, sportsbooks) {
        const docRef = doc(db, 'users', this.id)
        await updateDoc(docRef, {
            stateLocation: state,
            sportsbooks: sportsbooks
        })
    }

    async updateDefaultSport(sport) {
        const docRef = doc(db, 'users', this.id)
        await updateDoc(docRef, {
            currentDefaultSport: sport
        })
    }
}

const userConverter = {
    toFirestore: function(user) {
        return {
            id: user.id,
            email: user.email,
            createdAt: user.createdAt,
            invoiceStatus: user.invoiceStatus,
            stripeCustomerEmail: user.stripeCustomerEmail,
            subscription: user.subscription,
            stateLocation: user.stateLocation,
            sportsbooks: user.sportsbooks,
            currentDefaultSport: user.currentDefaultSport,
            previewEndsAt: user.previewEndsAt
        }
    },
    fromFirestore: function(snapshot, options) {
        const data = snapshot.data(options)
        return new User(snapshot.documentID, data.email, data.createdAt, data.invoiceStatus, data.stripeCustomerEmail, data.subscription, data.stateLocation, data.sportsbooks, data.currentDefaultSport, data.previewEndsAt)
    }
}

export { User, userConverter }