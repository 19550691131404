import sportsbooknames from '../SportsbookNames.json'
import statesByCode from '../StatesByCode.json'
import { useState } from 'react'
import check from '../assets/check_circle.png';

function SelectSportsbooksModal({ isOpen, onClose, sportsbooksData, currentlySelectedState, currentlySelectedSportsbooks, updateSelectedStateSportsbooks }) {

    const [selectingState, setSelectingState] = useState(!currentlySelectedState)
    const [selectedState, setSelectedState] = useState(currentlySelectedState)

    async function sportsbookClicked(s) {
        if (currentlySelectedSportsbooks.includes(s)) {
            const updatedSportsboooks = currentlySelectedSportsbooks.filter(sportsbook => {
                return s !== sportsbook
            })
            await updateSelectedStateSportsbooks(selectedState, updatedSportsboooks)
        } else {
            await updateSelectedStateSportsbooks(selectedState, [...currentlySelectedSportsbooks, s])
        }
    }

    const allSportsbooksSorted = Object.keys(sportsbooksData?.bySportsbook ?? []).sort((a, b) => { return sportsbooknames[a] > sportsbooknames[b] ? 1 : -1 })

    function availableSportsbooks(state) {
        if (state && sportsbooksData?.byState[state]) {
            return allSportsbooksSorted.filter(s => {
                return sportsbooksData.byState[state].includes(s)
            })
        } else {
            return allSportsbooksSorted
        }
    }

    async function stateSelected(state) {
        const newlyAvailableBooks = availableSportsbooks(state)
        setSelectedState(state)
        setSelectingState(false)
        await updateSelectedStateSportsbooks(state, newlyAvailableBooks)
    }

    if (isOpen) {
        return (<div className="z-30 fixed bg-black bg-opacity-75 w-full min-h-screen grow relative">
            <div className="flex flex-col bg-white rounded grow relative max-w-4xl m-auto mt-4 mb-4 py-2">
                <div className="flex flex-row justify-between items-center w-full">
                    <label className="font-semibold text-lg ml-2">Select Sportsbooks</label>
                    <button className="mr-2" onClick={onClose}>Close</button>
                </div>
                <div className="flex flex-col grow relative space-y-2 mx-2">
                    <button className='h-12 border rounded' onClick={() => setSelectingState(!selectingState)}>{selectingState ? 'Select your state' : (selectedState ? `Available in ${selectedState}` : 'Filter by state')}</button>
                    {
                        selectingState &&
                        <button key={'all'} className={`flex flex-row items-center rounded h-12 bg-gray-100`} onClick={() => stateSelected(null)}>
                            <label className='ml-2'>{'All' + ' (' + Object.keys(sportsbooksData?.bySportsbook ?? {}).length + ' Sportsbooks)'}</label>
                        </button>
                    }
                    {
                        selectingState ?
                            Object.keys(sportsbooksData?.byState ?? {}).sort((a, b) => {
                                return a > b ? 1 : -1
                            }).map((s, i) => {
                                return (
                                    <button key={i} className={`flex flex-row items-center rounded h-12 bg-gray-100`} onClick={() => stateSelected(s)}>
                                        <label className='ml-2'>{statesByCode[s] + ' (' + sportsbooksData?.byState[s].length + ' Sportsbooks)'}</label>
                                    </button>
                                )
                            }) :
                            availableSportsbooks(selectedState).map((s, i) => {
                                const highlighted = currentlySelectedSportsbooks.includes(s)
                                return (
                                    <button key={i} onClick={() => sportsbookClicked(s)} className={`flex flex-row justify-between items-center rounded h-12 border ${highlighted ? 'border-black shadow' : ''}`}>
                                        <div className={`flex flex-row items-center h-full`} >
                                            <img src={`/images/${s.toLowerCase()}_square.png`} className="h-full rounded-l-sm" />
                                            <label className={`ml-2 ${highlighted ? 'font-semibold' : 'font-regular text-gray-500'}`}>{sportsbooknames[s]}</label>
                                        </div>
                                        {
                                            highlighted &&
                                            <img src={check} className='h-6 mr-2' />
                                        }
                                    </button>

                                )
                            })
                    }
                </div>
            </div>
        </div>)
    } else {
        return <></>
    }
}

export default SelectSportsbooksModal