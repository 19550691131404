import { onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"

function useFirestoreDocument(reference, reload) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [document, setDocument] = useState(null)

    useEffect(() => {
        const unsubscribe = onSnapshot(reference, (d) => {
            if (d.exists()) {
                const a = d.data()
                a.id = d.id
                setDocument(a)
            } else {
                setDocument(null)
            }
            setLoading(false)
        })
        return () => unsubscribe()
    }, [reload])

    return {
        error,
        loading,
        document
    }
}

export default useFirestoreDocument