import { getAmericanOdds } from "../PicksData";
import sportsbooknames from '../SportsbookNames.json'
import { proportionToPct } from "./GameDataComponent";

function GameOddsSnapshotComponent({ awayTeam, homeTeam, oddsInfo }) {

    function OddsComponent({ provider, oddsText, valueText, customName }) {
        const txt = customName ?? sportsbooknames[provider]
        return (
            <div className="flex flex-row justify-between text-sm">
                <div className="flex flex-row space-x-2 items-center">
                    <img className={"w-6 h-6 " + (provider ? "":"invisible")} src={`/images/${provider}_square.png`} alt="sportsbook logo" />
                    <label className={(provider ? "":"italic font-semibold")}>{txt}</label>
                </div>
                <div className="flex flex-row space-x-2 text-right items-center">
                    <label className={"w-10 " + (provider ? "":"italic font-semibold")}>{oddsText}</label>
                    <label className="w-10 ">{valueText}</label>
                </div>
            </div>
        )
    }

    function TeamOddsComponent({ home }) {
        const mean = home ? oddsInfo.homesummaries?.mean : oddsInfo.awaysummaries?.mean
        const outcomes = home ? oddsInfo.homeOutcomes : oddsInfo.awayOutcomes
        return <div className="flex flex-col w-full">
            <div className="flex flex-row space-x-2 text-sm items-center">
                <img className="w-6 h-6" src={home ? homeTeam.logo : awayTeam.logo} alt="team logo" />
                <label className="text-lg">{home ? homeTeam.displayName : awayTeam.displayName}</label>
            </div>
            <OddsComponent customName={'Average Money Line'} oddsText={getAmericanOdds(mean)} />
            {
                outcomes.sort((a, b) => b.price - a.price).map((outcome, index) => {
                    const delta = outcome.price - mean
                    const pctDelta = delta / mean
                    return <OddsComponent key={index} provider={outcome.provider} oddsText={getAmericanOdds(outcome.price)} valueText={proportionToPct(pctDelta, 1, true)} />
                })
            }
        </div>
    }

    return (
        <div className="flex flex-col space-y-4 space-x-0 md:flex-row md:space-y-0 md:space-x-2 p-2">
            <TeamOddsComponent home={false} />
            <TeamOddsComponent home={true} />
        </div>
    )
}

export default GameOddsSnapshotComponent;