import { useState } from "react";
import { getAmericanOdds, getDecimalOdds } from "../PicksData";
import logo from '../assets/pickpricer_lockup.png';
import { useNavigate } from "react-router-dom";

function Calculators() {

    let navigate = useNavigate()
    const [betAmount, setBetAmount] = useState('')
    const [odds, setOdds] = useState('')
    const borderColor = "border-neutral-500"

    function logoClicked() {
        // navigate('/')
        window.location.href = 'https://pickpricer.com'
    }

    const allOdds = getOdds(odds)
    const impliedProbability = allOdds.oddsType === 'unknown' ? '-' : String(Math.round((1 / allOdds.decimalOdds) * 10000) / 100) + '%'
    const toWin = (isNaN(betAmount) || allOdds.oddsType === 'unknown') ? '-' : String(Math.round(betAmount * (allOdds.decimalOdds - 1) * 100) / 100)

    // const calculatorType = 'singleBet'
    const calculatorType = 'parlay'
    const [parlayOdds, setParlayOdds] = useState([null, null])

    function handleDecimalOdds(decimalOdds, betIndex) {
        // parlayOdds.push(decimalOdds)
        console.log(decimalOdds, ": ", betIndex)
    }

    function handleDelete(betIndex) {
        let newArray = [
            ...parlayOdds.slice(0, betIndex),
            ...parlayOdds.slice(betIndex + 1)
        ];
        setParlayOdds(newArray)
    }

    return (
        <div className="w-screen flex justify-center">
            <div className="max-w-md flex flex-col items-center space-y-4">
                <button className="w-1/2" type="button" onClick={logoClicked}>
                    <img src={logo} className="w-full my-4" />
                </button>

                <div className="border rounded p-2 flex flex-col space-y-2 max-w-md">
                    <label className="pl-1 text-xl font-bold">Sports Bet Calculator</label>
                    {
                        calculatorType === 'singleBet' &&
                        <div className="flex flex-col space-y-2">
                            <div className="w-full flex flex-row space-x-2">
                                <InputBoxComponent title="Bet Amount ($)" update={setBetAmount} type="text" />
                                <InputBoxComponent title="Odds (Any Format)" update={setOdds} type="text" />
                            </div>

                            <div className={`border rounded ${borderColor}`}>
                                <table className={`w-full table-fixed border-collapse rounded-t`}>
                                    <thead className={`bg-gray-100 text-sm rounded-t border-b ${borderColor}`}>
                                        <tr>
                                            <th className={`border-r ${borderColor} rounded-tl`}>To Win</th>
                                            <th className="rounded-tr">Payout (Winnings + Bet)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center text-2xl">
                                            <td className={`border-r ${borderColor}`}>{toWin === '-' ? '-' : formatMoney(toWin)}</td>
                                            <td>{toWin === '-' ? '-' : formatMoney(parseFloat(toWin) + parseFloat(betAmount))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={`border rounded ${borderColor}`}>
                                <table className={`w-full table-fixed rounded-t`}>
                                    <thead className={`bg-gray-100 text-sm rounded-t border-b ${borderColor}`}>
                                        <tr>
                                            <th className={`border-r rounded-tl ${borderColor}`}>American Odds</th>
                                            <th className={`border-r ${borderColor}`}>Decimal Odds</th>
                                            <th className={`border-r ${borderColor}`}>Fractional Odds</th>
                                            <th className="rounded-tr">Implied Probability</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center text-lg">
                                            <td className={`border-r ${borderColor}`}>{allOdds.oddsType === 'unknown' ? '-' : allOdds.americanOdds}</td>
                                            <td className={`border-r ${borderColor}`}>{allOdds.oddsType === 'unknown' ? '-' : Math.round(allOdds.decimalOdds * 10000) / 10000}</td>
                                            <td className={`border-r ${borderColor}`}>{allOdds.oddsType === 'unknown' ? '-' : allOdds.fractionalOdds}</td>
                                            <td>{allOdds.oddsType === 'unknown' ? '-' : impliedProbability}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        calculatorType === 'parlay' &&
                        <div className="flex flex-col space-y-2">
                            <InputBoxComponent title="Bet Amount ($)" update={setBetAmount} type="text" />
                            {
                                parlayOdds.map((o, i) => {
                                    return <ParlayOddsInput key={o + i} handleDecimalOdds={handleDecimalOdds} index={i} handleDelete={handleDelete}/>
                                })
                            }
                        
                            <button type="button" onClick={() => setParlayOdds([...parlayOdds, null])}>Add Bet</button>

                            <div className={`border rounded ${borderColor}`}>
                                <table className={`w-full table-fixed border-collapse rounded-t`}>
                                    <thead className={`bg-gray-100 text-sm rounded-t border-b ${borderColor}`}>
                                        <tr>
                                            <th className={`border-r ${borderColor} rounded-tl`}>To Win</th>
                                            <th className="rounded-tr">Payout (Winnings + Bet)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center text-2xl">
                                            <td className={`border-r ${borderColor}`}>{toWin === '-' ? '-' : formatMoney(toWin)}</td>
                                            <td>{toWin === '-' ? '-' : formatMoney(parseFloat(toWin) + parseFloat(betAmount))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

function getOdds(oddsInput) {
    let oddsType = 'unknown'
    let americanOdds = null
    let fractionalOdds = null
    let decimalOdds = null
    if (!oddsInput) {
        return { oddsType, americanOdds, decimalOdds, fractionalOdds }
    } else if (oddsInput.includes('/')) {
        oddsType = 'fractional'
        const splitOdds = oddsInput.split('/')
        const numerator = parseFloat(splitOdds[0])
        const denominator = parseFloat(splitOdds[1])
        if (denominator === 0 || isNaN(numerator) || isNaN(denominator)) {
            return { oddsType, americanOdds, decimalOdds, fractionalOdds }
        }
        decimalOdds = numerator / denominator + 1
        fractionalOdds = oddsInput
        americanOdds = getAmericanOdds(decimalOdds)
    } else if ((oddsInput.charAt(0) === '+' && oddsInput.length > 1) || parseFloat(oddsInput) < 0) {
        const americanOddsNumber = oddsInput.charAt(0) === '+' ? parseFloat(oddsInput.substring(1)) : parseFloat(oddsInput)
        if (Math.abs(americanOddsNumber) < 100) {
            return { oddsType, americanOdds, decimalOdds, fractionalOdds }
        }
        oddsType = 'american'
        decimalOdds = getDecimalOdds(americanOddsNumber)
        const roundedDecimalOdds = Math.round(decimalOdds * 10000) / 10000
        fractionalOdds = decimalToImproperFraction(roundedDecimalOdds - 1)
        americanOdds = americanOddsNumber > 0 ? "+" + String(americanOddsNumber) : String(americanOddsNumber)
    } else if (isNaN(oddsInput)) {
        oddsType = 'unknown'
    } else if (oddsInput.includes('.') || parseFloat(oddsInput) < 100) {
        oddsType = 'decimal'
        decimalOdds = parseFloat(oddsInput)
        const roundedDecimalOdds = Math.round(decimalOdds * 10000) / 10000
        fractionalOdds = decimalToImproperFraction(roundedDecimalOdds - 1)
        americanOdds = getAmericanOdds(decimalOdds)
    } else {
        oddsType = 'american'
        const americanOddsNumber = parseFloat(oddsInput)
        americanOdds = americanOddsNumber > 0 ? "+" + String(americanOddsNumber) : String(americanOddsNumber)
        decimalOdds = getDecimalOdds(americanOddsNumber)
        const roundedDecimalOdds = Math.round(decimalOdds * 10000) / 10000
        fractionalOdds = decimalToImproperFraction(roundedDecimalOdds - 1)
    }

    return { oddsType, americanOdds, decimalOdds, fractionalOdds }
}

function ParlayOddsInput({ handleDecimalOdds, index, handleDelete, currentOdds }) {
    const [odds, setOdds] = useState('')
    const processedOdds = getOdds(odds)

    return (
        <div className="flex flex-row space-x-2">
            <InputBoxComponent title={`Odds (${ processedOdds.oddsType === 'unknown' ? 'any format':processedOdds.oddsType })`} update={(v) => {
                setOdds(v)
                handleDecimalOdds(processedOdds.decimalOdds, index)
            }} type="text" value={currentOdds} />
            <button type="button" onClick={() => handleDelete(index)}>delete</button>
        </div>
    )
}

function InputBoxComponent({ title, update, type, value=null }) {
    return (
        <div className="flex flex-col space-y-0.5 w-full">
            <label className="text-sm font-semibold pl-1">{title.toUpperCase()}</label>
            <input className={`w-full border text-2xl rounded p-1 bg-gray-100`} type={type} value={value} onChange={(e) => update(e.target.value)} />
        </div>
    )
}

function gcd(a, b) {
    // Function to calculate the Greatest Common Divisor
    if (b === 0) return a;
    return gcd(b, a % b);
}

function decimalToImproperFraction(decimal) {
    const isNegative = decimal < 0;
    decimal = Math.abs(decimal);

    const decimalStr = decimal.toString();
    const parts = decimalStr.split('.');
    const integerPart = parseInt(parts[0], 10);
    const decimalPart = parts[1] || '0';
    const len = decimalPart.length;
    const denominator = Math.pow(10, len);
    const numerator = parseInt(decimalPart, 10) + integerPart * denominator;

    const commonDivisor = gcd(numerator, denominator);

    const simplifiedNumerator = (numerator / commonDivisor).toFixed(0);
    const simplifiedDenominator = (denominator / commonDivisor).toFixed(0);
    const fraction = `${isNegative ? '-' : ''}${simplifiedNumerator}/${simplifiedDenominator}`;

    if (simplifiedNumerator > 999 || simplifiedDenominator > 999) {
        return ""
    } else {
        return fraction;
    }

}

function formatMoney(amount, locale = 'en-US', currency = 'USD') {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
}

export default Calculators;