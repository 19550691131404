import { logout } from "../firebase/firebase"

function MenuComponent({ email, show }) {

    function logoutClicked() {
        if (window.confirm("Are you sure you want to log out?")) {
            logout()
        }
    }

    return (<div className={`flex bg-white justify-end ${show ? '' : 'hidden'}`}>
        <div className="flex flex-col grow max-w-4xl h-full">
            <button className="p-2 text-black text-right font-regular text-sm">{email}</button>
            <button className="p-2 text-black text-right font-bold" onClick={() => window.open('mailto:leland@pickpricer.com')}>Contact</button>
            <button className="p-2 text-black text-right font-bold" onClick={() => window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL)}>Billing</button>
            <button className="p-2 text-black text-right font-bold" onClick={() => logoutClicked()}>Log Out</button>
        </div>
    </div>)
}

export default MenuComponent