import { React, useEffect, useState } from "react";
import { db } from "./firebase/firebase";
import GameDataComponent, { profitProportion, proportionToPct } from "./components/GameDataComponent";
import GameTableRow from "./components/GameTableRow";
import NavigationBar, { sportTitles } from "./components/NavigationBar";
import MenuComponent from "./components/MenuComponent";
import { doc, getDoc } from "firebase/firestore";
import SelectSportsbooksModal from "./components/SelectSportsbooksModal";
import downCaret from './assets/down_caret.png'
import GameDetailsModal from "./components/GameDetailsModal";
import NoGamesComponent from "./components/NoGamesComponent";
import useSportLeagueData from "./firebase/useSportLeagueData";
import { logout } from "./firebase/firebase"
import { useParams, useSearchParams } from "react-router-dom";
import useFirestoreDocument from "./firebase/useFirestoreDocument";
import { getAmericanOdds } from "./PicksData";
import { arbitragePct, profitPct } from "./components/GameDataComponent";
import { MultiSelect } from "react-multi-select-component";


function ArbitragesTable() {

    const params = useParams()

    const arbsLoader = useFirestoreDocument(doc(db, `arbs/${params.date}`), null)

    const [arbDetails, setArbDetails] = useState(null)
    const [selectedSportsbooks, setSelectedSportsbooks] = useState([])
    const [minimumReturn, setMinimumReturn] = useState(0.0)

    const [sportsbookOptions, setSportsbookOptions] = useState([])

    // const allSportsbooks = [
    //     'draftkings',
    //     'fanduel',
    //     'betmgm',
    //     'williamhill_us',
    //     'espnbet',
    //     'betrivers',
    //     'pointsbetus',
    //     'windcreek',
    //     'hardrockbet',
    //     'wynnbet',
    //     'unibet_us',
    //     'betparx',
    //     ,

    // ]

    // const [includedSportsbooks, setIncludedSportsbooks] = useState(['fanduel', 'draftkings', 'betmgm', 'williamhill_us'])

    useEffect(() => {
        async function getSportsbooksByState() {
            const docRef = doc(db, 'variables', 'sportsbooksByState')
            const docSnap = await getDoc(docRef)
            console.log('sportsbooks by state: ', docSnap.data())
            
            const sportsbooks = Object.keys(docSnap.data().bySportsbook)
            sportsbooks.sort((a,b) => {
                return docSnap.data().bySportsbook[b].length - docSnap.data().bySportsbook[a].length
            })
            console.log('sorted sportsbooks: ', sportsbooks)
            setSportsbookOptions(sportsbooks.map(b => {
                return { label: b, value: b }
            }))

            // if (includedSportsbooks === null) {
            //     setIncludedSportsbooks(Object.keys(docSnap.data().bySportsbook))
            // }
            // setSportsbooksData(docSnap.data())
        }
        getSportsbooksByState()
    }, [])

    if (arbsLoader.loading || sportsbookOptions.length === 0) {
        return <>loading...</>
    }

    // return <>loaded arbs: {arbsLoader.document.arbs.length}</>

    const headerStyle = "sticky top-0 border border-slate-300 bg-gray-100"

    const lastupdateddateoptions = { hour: 'numeric', minute: '2-digit' };
    const lastupdatedtimeoptions = { hour: 'numeric', minute: '2-digit', second: '2-digit' };

    function processedArbs() {
        return arbsLoader.document.arbs.map(arb => {
            // const awayMoneyLine = arb.awayMoneyLines.find(ml => {
            //     return selectedSportsbooks.some(b => b.value === ml.provider)
            // })
            // const homeMoneyLine = arb.homeMoneyLines.find(ml => {
            //     return selectedSportsbooks.some(b => b.value === ml.provider)
            // })

            const sportsbooksIncluded = selectedSportsbooks.length === 0 ? sportsbookOptions : selectedSportsbooks

            const filteredAwayMoneyLines = arb.awayMoneyLines.filter(ml => {
                return sportsbooksIncluded.some(b => b.value === ml.provider)
            })
            const filteredHomeMoneyLines = arb.homeMoneyLines.filter(ml => {
                return sportsbooksIncluded.some(b => b.value === ml.provider)
            })

            let a = arb
            a.filteredAwayMoneyLines = filteredAwayMoneyLines
            a.filteredHomeMoneyLines = filteredHomeMoneyLines

            
            a.bestawaySelected = filteredAwayMoneyLines[0]
            a.besthomeSelected = filteredHomeMoneyLines[0]

            if (filteredAwayMoneyLines.length === 0 || filteredHomeMoneyLines.length === 0) {
                a.profitFromSelected = -1
                return a
            }
            a.profitFromSelected = profitProportion(a.bestawaySelected?.price, a.besthomeSelected?.price)
            return a

            // if (!awayMoneyLine || !homeMoneyLine) {
            //     return false
            // }
            // const arbpct = arbitragePct(homeMoneyLine.price, awayMoneyLine.price)
            // return arbpct < 1
        }).filter(arb => {
            if (!arb.filteredAwayMoneyLines || !arb.filteredHomeMoneyLines) {
                return false
            }
            return arb.profitFromSelected >= minimumReturn
        })
    }

    function getTexts(processedArb) {
        let awayBetText = `${processedArb.awayAbbrev} ${getAmericanOdds(processedArb.bestawaySelected.price)}, ${processedArb.bestawaySelected.provider}`
        if (processedArb.filteredAwayMoneyLines.length > 1) {
            awayBetText += ` (${((processedArb.filteredAwayMoneyLines[0].price - processedArb.filteredAwayMoneyLines[1].price) / processedArb.filteredAwayMoneyLines[1].price * 100).toFixed(2) + "%"})`
        }

        let homeBetText = `${processedArb.homeAbbrev} ${getAmericanOdds(processedArb.besthomeSelected.price)}, ${processedArb.besthomeSelected.provider}`
        if (processedArb.filteredHomeMoneyLines.length > 1) {
            homeBetText += ` (${((processedArb.filteredHomeMoneyLines[0].price - processedArb.filteredHomeMoneyLines[1].price) / processedArb.filteredHomeMoneyLines[1].price * 100).toFixed(2) + "%"})`
        }

        // const arbpct = arbitragePct(processedArb.bestawaySelected.price, processedArb.besthomeSelected.price)
        // const profPct = profitPct(arbpct)

        const prof = profitProportion(processedArb.bestawaySelected.price, processedArb.besthomeSelected.price)
        return { awayBetText, homeBetText, prof }
    }

    function ThresholdSelector() {
        return <div className="sticky top-0 h-10 z-20 bg-white">
            <select
                onChange={(e) => setMinimumReturn(e.target.value)}
                defaultValue={0.0}
                value={minimumReturn}
            >
                <option value={0.0}>All arbitrages</option>
                <option value={0.05}>minumum 5% return</option>
                <option value={0.1}>minimum 10% return</option>
            </select>
        </div>
    }

    function SportsbookSelector() {
        return <div className="sticky top-10 h-10 z-20">
            <MultiSelect
                options={sportsbookOptions}
                value={selectedSportsbooks}
                onChange={setSelectedSportsbooks}
                labelledBy="Select Sportsbooks"
            />
        </div>
    }

    if (arbDetails) {
        return (
            <div>
                {/* <SportsbookSelector /> */}
                <button onClick={() => setArbDetails(null)}>back</button>
                <div>{arbDetails.game} {arbDetails.statusDetail}</div>
                <table className="w-full table-fixed overflow-scroll border-collapse border border-slate-300">
                    <thead className="bg-gray-100 sticky top-0 z-10">
                        <tr>
                            <th className="sticky top-0 left-0 border border-slate-300 bg-gray-100 w-24 z-20"></th>
                            {
                                arbDetails.filteredAwayMoneyLines.map((ml, i) => {
                                    const d = new Date(ml.lastUpdated)
                                    return (
                                        <th key={i} className="sticky top-0 z-20 border border-slate-300 w-40">
                                            <label>{ml.provider}</label><br />
                                            <label>{getAmericanOdds(ml.price)}</label><br />
                                            <label>{d.toLocaleTimeString("en-US", lastupdatedtimeoptions)}</label>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arbDetails.filteredHomeMoneyLines.map((ml, i) => {
                                const d = new Date(ml.lastUpdated)
                                return (
                                    <tr key={i}>
                                        <td className="border border-slate-300 z-20 sticky bg-gray-100 left-0">{ml.provider}<br />{getAmericanOdds(ml.price)}<br />{d.toLocaleTimeString("en-US", lastupdatedtimeoptions)}</td>
                                        {
                                            arbDetails.filteredAwayMoneyLines.map((awayML, j) => {
                                                const d = new Date(awayML.lastUpdated)
                                                const arbpct = arbitragePct(ml.price, awayML.price)
                                                const profPct = profitPct(arbpct)
                                                return (
                                                    <td key={j} className="border border-slate-300">
                                                        {profPct}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div>
            <ThresholdSelector />
            <SportsbookSelector />
            <table className="w-full overflow-scroll border-collapse border border-slate-300">
                <thead className="bg-gray-100 sticky top-20 z-10">
                    <tr>
                        <th className="sticky left-0 border border-slate-300 bg-gray-100 w-24 z-20">Time</th>
                        <th className={headerStyle}>Away</th>
                        <th className={headerStyle}>Home</th>
                        <th className={headerStyle}>Situation</th>
                        <th className={headerStyle}>Bets</th>
                        <th className={headerStyle}>Guaranteed Return</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        // arbsLoader.document.arbs
                        //     .filter(arb => {
                        //         return arb.guaranteedProfit > 0.1

                        //         // const awayMoneyLine = arb.awayMoneyLines.find(ml => includedSportsbooks.includes(ml.provider))
                        //         // const homeMoneyLine = arb.homeMoneyLines.find(ml => includedSportsbooks.includes(ml.provider))
                        //         // if (!awayMoneyLine || !homeMoneyLine) {
                        //         //     return false
                        //         // }
                        //         // const arbpct = arbitragePct(homeMoneyLine.price, awayMoneyLine.price)
                        //         // return arbpct < 1
                        //     })
                        processedArbs()
                            .map((arb, index) => {
                                const date = arb.lastUpdated.toDate()

                                // let awayBetText = `${arb.awayAbbrev} ${getAmericanOdds(arb.bestaway)}, ${arb.bestawayprovider}`
                                // if (arb.awayMoneyLines.length > 1) {
                                //     awayBetText += ` (${((arb.awayMoneyLines[0].price - arb.awayMoneyLines[1].price) / arb.awayMoneyLines[1].price * 100).toFixed(2) + "%"})`
                                // }

                                // let homeBetText = `${arb.homeAbbrev} ${getAmericanOdds(arb.besthome)}, ${arb.besthomeprovider}`
                                // if (arb.homeMoneyLines.length > 1) {
                                //     homeBetText += ` (${((arb.homeMoneyLines[0].price - arb.homeMoneyLines[1].price) / arb.homeMoneyLines[1].price * 100).toFixed(2) + "%"})`
                                // }

                                const { awayBetText, homeBetText, prof } = getTexts(arb)

                                return (
                                    <tr key={index} onClick={() => setArbDetails(arb)}>
                                        <td className="border border-slate-300">{date.toLocaleTimeString("en-US", lastupdateddateoptions)}</td>
                                        <td className="border border-slate-300">{arb.awayAbbrev}</td>
                                        <td className="border border-slate-300">{arb.homeAbbrev}</td>
                                        <td className="border border-slate-300">{arb.statusDetail}</td>
                                        <td className="border border-slate-300">{awayBetText}<br />{homeBetText}</td>
                                        <td className="border border-slate-300">{proportionToPct(prof)}</td>
                                        {/* <td className="border border-slate-300">{(arb.guaranteedProfit * 100).toFixed(2) + "%"}</td> */}
                                    </tr>
                                )
                            })}
                </tbody>
            </table>
        </div>

    )


}

export default ArbitragesTable;