import { logout } from "../firebase/firebase"
import { Link } from "react-router-dom"
import logo from '../assets/pickpricer_lockup.png';
import hamburgeMenu from '../assets/hamburger.png';
import PreviewCountdown from "./PreviewCountdown";

const sportTitles = {
    "baseball_mlb": "MLB",
    "hockey_nhl": "NHL",
    "basketball_nba": "NBA",
    "basketball_mens-college-basketball": "NCAAM",
    "basketball_wnba": "WNBA",
}

function NavigationBar({ menuShown, handleMenuClick, previewDeadline, handlePreviewClick, handleEndOfPreview }) {

    return (
        <div className="flex flex-row justify-between p-2 h-14 bg-white items-center w-full z-50">
            <div className="text-black font-bold flex flex-row">
                <img src={logo} className="h-9" />
            </div>
            <div className="flex flex-row space-x-4">
                {
                    previewDeadline && <PreviewCountdown deadline={previewDeadline} handleClick={handlePreviewClick} handleEndOfPreview={handleEndOfPreview}/>
                }
                <button onClick={() => handleMenuClick()}>
                    <img src={hamburgeMenu} className="h-4" />
                </button>
            </div>

            {/* <div className="flex flex-row space-x-2"> */}
            {/* {
                    sports?.map((s, i) => {
                        return <NavButton key={i} sport={s} handler={handleSportSelection} />
                    })
                } */
                // <button className="text-xs text-gray-500 font-semibold bg-gray-100 px-3 py-2 rounded" onClick={() => handleMenuClick()}>{ menuShown ? 'Close':'Menu'}</button>}
            }
            {/* </div> */}
        </div>
    )
}

export default NavigationBar
export { sportTitles }