import { collection, onSnapshot, query, orderBy, limit } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, getCollection } from "./firebase";
import { dayDataConverter } from "../models/DayData";
import { bestMLs } from "../components/DaySummary";

// optional converter
function useSportLeagueDateData(sportLeague, date) {
    const [gamesLoading, setGamesLoading] = useState(true)
    const [oddsLoading, setOddsLoading] = useState(true)

    const [gamesSnapshots, setGamesSnapshots] = useState([])
    const [oddsSnapshots, setOddsSnapshots] = useState([])

    const [games, setGames] = useState([])
    const [bestOdds, setBestOdds] = useState({})

    const lastupdateddateoptions = { hour: 'numeric', minute: '2-digit' };

    useEffect(() => {
        setGamesLoading(true)
        setOddsLoading(true)
        const basePath = `data/${sportLeague}/dates/${date}`
        const gamesQuery = query(collection(db, `${basePath}/gameSnapshots`).withConverter(dayDataConverter), orderBy('gamesLastUpdated', 'desc'))
        const unsubGamesData = onSnapshot(gamesQuery, (querySnapshot) => {
            const gids = []
            const gsnaps = []
            querySnapshot?.docs.forEach(doc => {
                const data = doc.data()
                data.id = doc.id
                const gamesDate = data.gamesLastUpdated.toDate()
                data.timeString = gamesDate.toLocaleTimeString("en-US", lastupdateddateoptions)
                data.d = gamesDate
                gsnaps.push(data)

                data.games.forEach(game => {
                    if (game.statusName === 'STATUS_FINAL' && !gids.find(g => g.id === game.id)) {
                        gids.push({
                            id: game.id,
                            date: game.date,
                            homeTeam: game.homeTeam,
                            awayTeam: game.awayTeam,
                            homeAbbrev: game.homeAbbrev,
                            awayAbbrev: game.awayAbbrev,
                            homeid: game.homeid,
                            awayid: game.awayid,
                            homeWon: game.winningTeamID === game.homeid
                        })
                    }
                })
            })
            gsnaps.sort((a, b) => {
                if (a.d < b.d) {
                    return -1
                } else if (a.d > b.d) {
                    return 1
                } else {
                    return 0
                }
            })
            gids.sort((a, b) => {
                const adate = new Date(a.date)
                const bdate = new Date(b.date)
                if (adate < bdate) {
                    return -1
                } else if (adate > bdate) {
                    return 1
                } else {
                    return 0
                }
            })
            setGamesSnapshots(gsnaps)
            setGames(gids)
            setGamesLoading(false)
            // if (querySnapshot && querySnapshot.docs.length > 0) {
            //     setGamesSnapshots(querySnapshot.docs.map(doc => {
            //         const data = doc.data()
            //         const gamesDate = data.gamesLastUpdated.toDate()
            //         data.timeString = gamesDate.toLocaleTimeString("en-US", lastupdateddateoptions)
            //         return data
            //     }))
            //     setGamesLoading(false)
            // } else {
            //     setGamesSnapshots([])
            //     setGamesLoading(false)
            // }
        })

        const oddsQuery = query(collection(db, `${basePath}/odds`), orderBy('oddsLastUpdated', 'desc'))
        const unsubOddsData = onSnapshot(oddsQuery, (querySnapshot) => {
            if (querySnapshot && querySnapshot.docs.length > 0) {

                const oddSnaps = []
                const optimalodds = {}
                querySnapshot.docs.forEach(doc => {
                    const data = doc.data()
                    data.id = doc.id
                    oddSnaps.push(data)

                    data.odds.forEach(o => {
                        const currentOptimal = optimalodds[o.awayTeam + "@" + o.homeTeam]
                        const best = bestMLs(o)
                        if (currentOptimal) {
                            // if (o.price > currentOptimal.price) {
                            //     optimalodds[o.awayTeam + "@" + o.homeTeam] = o
                            // }

                            if (best.besthome > currentOptimal.besthome) {
                                optimalodds[o.awayTeam + "@" + o.homeTeam].besthome = best.besthome
                                optimalodds[o.awayTeam + "@" + o.homeTeam].besthomeprovider = best.besthomeprovider
                            }
                            if (best.bestaway > currentOptimal.bestaway) {
                                optimalodds[o.awayTeam + "@" + o.homeTeam].bestaway = best.bestaway
                                optimalodds[o.awayTeam + "@" + o.homeTeam].bestawayprovider = best.bestawayprovider
                            }
                        } else {
                            optimalodds[o.awayTeam + "@" + o.homeTeam] = best
                        }
                    })                    
                })
                setBestOdds(optimalodds)
                setOddsSnapshots(oddSnaps)

                // setOddsSnapshots(querySnapshot.docs.map(doc => {
                //     const data = doc.data()
                //     data.id = doc.id
                //     return data
                // }))
                setOddsLoading(false)
            } else {
                setOddsSnapshots([])
                setOddsLoading(false)
            }
        })
        return () => {
            unsubGamesData()
            unsubOddsData()
        }
    }, [sportLeague, date])

    return {
        sportLeague: sportLeague,
        loading: gamesLoading || oddsLoading,
        gamesSnapshots: gamesSnapshots,
        oddsSnapshots: oddsSnapshots,
        games: games,
        bestOdds: bestOdds
    }
}

export default useSportLeagueDateData