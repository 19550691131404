import { bestMLs } from "../components/DaySummary"
import { arbitragePct, individualArbPct, profitPct, stakePct } from "../components/GameDataComponent"

class DayData {
    constructor(id, sportLeague, games, gamesLastUpdated, predictions, odds, currentInProgressOdds, oddsLastUpdated, dateInteger) {
        this.id = id
        this.sportLeague = sportLeague
        this.games = games
        this.gamesLastUpdated = gamesLastUpdated
        this.predictions = predictions
        // this.odds = odds ?? []
        // this.currentInProgressOdds = currentInProgressOdds ?? []
        // this.oddsLastUpdated = oddsLastUpdated
        this.dateInteger = dateInteger


        // const dateoptions = { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' };
        const dateoptions = { hour: 'numeric', minute: '2-digit' };

        // const includedSportsbooks = ['FanDuel', 'DraftKings', 'Caesars', 'BetMGM']

        this.aggregatedData = games.map(g => {
            let gamedata = g
            const sportLeague = g.sport + "_" + g.league
            const predictions = this.predictions.filter(p => {
                return p.sportLeague === sportLeague && p.id === g.id
            })
            gamedata.predictions = predictions

            // const oddsList = (g.statusName === "STATUS_SCHEDULED") ? this.odds : this.currentInProgressOdds
            // const oddsList = this.odds
            // const gameOdds = oddsList.find(o => {
            //     return o.homeTeam === g.homeTeam &&
            //         o.awayTeam === g.awayTeam
            // })

            let date = new Date(g.date)
            gamedata.rawdate = date
            gamedata.dateText = date.toLocaleDateString("en-US", dateoptions)
            gamedata.timeText = date.toLocaleTimeString("en-US", dateoptions)

            // if (!gameOdds || !gameOdds.bookmakers) {
            //     return gamedata
            // }

            // gamedata.gameOdds = gameOdds
            // gamedata.oddsApiHomeTeam = gameOdds.oddsApiHomeTeam
            // gamedata.oddsApiAwayTeam = gameOdds.oddsApiAwayTeam

            return gamedata
        })



        // const lastupdateddateoptions = { hour: 'numeric', minute: '2-digit' };
        // const oddsDate = this.oddsLastUpdated.toDate()
        // const gamesDate = this.gamesLastUpdated.toDate()
        // this.lastUpdatedTexts = {
            // odds: oddsDate.toLocaleTimeString("en-US", lastupdateddateoptions),
            // games: gamesDate.toLocaleTimeString("en-US", lastupdateddateoptions)
        // }


        const providers = []
        this.predictions.forEach(p => {
            if (!providers.includes(p.provider)) {
                providers.push(p.provider)
            }
        })
        this.predictionProviders = providers
    }

    processedData(oddsList) {
        return this.aggregatedData.map(g => {
            let gamedata = g
    
            // const oddsList = (g.statusName === "STATUS_SCHEDULED") ? this.odds : this.currentInProgressOdds
            const gameOdds = oddsList.find(o => {
                return o.homeTeam === g.homeTeam &&
                    o.awayTeam === g.awayTeam
            })
    
            if (!gameOdds || !gameOdds.bookmakers) {
                return gamedata
            }
    
            gamedata.gameOdds = gameOdds
            
            const bMLs = bestMLs(gameOdds)
            gamedata.bestLines = bMLs

            gamedata.arbpct = arbitragePct(bMLs.besthome, bMLs.bestaway)
            gamedata.profPct = profitPct(gamedata.arbpct)
            gamedata.stakePctHome = stakePct(individualArbPct(bMLs.besthome), gamedata.arbpct)
            gamedata.stakePctAway = stakePct(individualArbPct(bMLs.bestaway), gamedata.arbpct)

            gamedata.oddsApiHomeTeam = gameOdds.oddsApiHomeTeam
            gamedata.oddsApiAwayTeam = gameOdds.oddsApiAwayTeam
    
            return gamedata
        })
        // .filter(g => {
        //     // defined if there is odds data available for the game
        //     return g.oddsApiHomeTeam
        // })
    }

    // get lastUpdatedTexts() {
    //     let dateoptions = { hour: 'numeric', minute: '2-digit' };
    //     let oddsDate = this.oddsLastUpdated.toDate()
    //     let gamesDate = this.gamesLastUpdated.toDate()
    //     return {
    //         odds: oddsDate.toLocaleTimeString("en-US", dateoptions),
    //         games: gamesDate.toLocaleTimeString("en-US", dateoptions)
    //     }
    // }

    // get predictionProviders() {
    //     const providers = []
    //     this.predictions.forEach(p => {
    //         if (!providers.includes(p.provider)) {
    //             providers.push(p.provider)
    //         }
    //     })
    //     return providers
    // }

    getBest(marketKey, a, b) {
        if (!a) {
            return b
        }
        if (!b) {
            return a
        }
        switch (marketKey) {
            case 'h2h':
                return a.price > b.price ? a : b
            case 'spreads':
                if (a.point === b.point) {
                    return a.price > b.price ? a : b
                } else {
                    return a.point > b.point ? a : b
                }
            case 'totals':
                if (a.point === b.point) {
                    return a.price > b.price ? a : b
                } else if (a.name === 'Over') {
                    return a.point > b.point ? b : a
                } else {
                    return a.point > b.point ? a : b
                }
            default:
                return null
        }
    }

    getWorst(marketKey, a, b) {
        if (!a) {
            return b
        }
        if (!b) {
            return a
        }
        switch (marketKey) {
            case 'h2h':
                return a.price > b.price ? b : a
            case 'spreads':
                if (a.point === b.point) {
                    return a.price > b.price ? b : a
                } else {
                    return a.point > b.point ? b : a
                }
            case 'totals':
                if (a.point === b.point) {
                    return a.price > b.price ? b : a
                } else if (a.name === 'Over') {
                    return a.point > b.point ? a : b
                } else {
                    return a.point > b.point ? b : a
                }
            default:
                return null
        }
    }

    bestMarketOutcomeOdds(includedSportsbooks, gamesData) {
        const byGameID = {}
        gamesData.forEach(g => {
            const bestMarketOutcomeOdds = {}
            const gamebookmakers = g.gameOdds?.bookmakers ?? []
            gamebookmakers.forEach(b => {
                const organizationKey = b.key
                const organizationTitle = b.title
                if (!includedSportsbooks || includedSportsbooks.includes(organizationKey)) {
                    b.markets.forEach(m => {
                        m.outcomes.forEach(o => {
                            const marketOutcome = m.key + "::" + o.name
                            const challengerOrganization = {
                                organizationKey: organizationKey,
                                organizationTitle: organizationTitle,
                            }
                            const challenger = { ...challengerOrganization, ...o }
                            const currentBest = bestMarketOutcomeOdds[marketOutcome]
                            bestMarketOutcomeOdds[marketOutcome] = this.getBest(m.key, currentBest, challenger)
                        })
                    })
                }
            })
            byGameID[g.id] = bestMarketOutcomeOdds
        })
        return byGameID
    }

    worstMarketOutcomeOdds(includedSportsbooks, gamesData) {
        const byGameID = {}
        gamesData.forEach(g => {
            const worstMarketOutcomeOdds = {}
            const gamebookmakers = g.gameOdds?.bookmakers ?? []
            gamebookmakers.forEach(b => {
                const organizationKey = b.key
                const organizationTitle = b.title
                if (!includedSportsbooks || includedSportsbooks.includes(organizationKey)) {
                    b.markets.forEach(m => {
                        m.outcomes.forEach(o => {
                            const marketOutcome = m.key + "::" + o.name
                            const challengerOrganization = {
                                organizationKey: organizationKey,
                                organizationTitle: organizationTitle,
                            }
                            const challenger = { ...challengerOrganization, ...o }
                            const currentWorst = worstMarketOutcomeOdds[marketOutcome]
                            worstMarketOutcomeOdds[marketOutcome] = this.getWorst(m.key, currentWorst, challenger)
                        })
                    })
                }
            })
            byGameID[g.id] = worstMarketOutcomeOdds
        })
        return byGameID
    }
}

const dayDataConverter = {
    toFirestore: (dayData) => {
        return {
            id: dayData.id,
            sportLeague: dayData.sportLeague,
            games: dayData.games,
            gamesLastUpdated: dayData.gamesLastUpdated,
            predictions: dayData.predictions,
            odds: dayData.odds,
            currentInProgressOdds: dayData.currentInProgressOdds ?? [],
            oddsLastUpdated: dayData.oddsLastUpdated,
            dateInteger: dayData.dateInteger
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options)
        return new DayData(snapshot.documentID, data.sportLeague, data.games, data.gamesLastUpdated, data.predictions, data.odds, data.currentInProgressOdds ?? [], data.oddsLastUpdated, data.dateInteger)
    }
}

export {
    DayData,
    dayDataConverter
}