// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, collection, doc } from "firebase/firestore";
import { dayDataConverter } from "../models/DayData";
import { createContext, useEffect, useState } from "react";
import { userConverter } from "../models/User";
import useFirestoreDocument from "./useFirestoreDocument";
import useUserDocument from "./useUserDocument";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAeOR5INNvxk9fvCUpms7vRi1Ah-we9nOs",
    authDomain: "pickpricer.firebaseapp.com",
    projectId: "pickpricer",
    storageBucket: "pickpricer.appspot.com",
    messagingSenderId: "449853424551",
    appId: "1:449853424551:web:382210a21adef1f6152c82",
    measurementId: "G-2DGWJ6KR8Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
export const db = getFirestore(app);

export const COLLECTIONS = {
    data: "data",
    users: "users"
}

const converterMap = {
    data: dayDataConverter,
    users: userConverter
}

// collections
export function getCollection(collectionID) {
    return collection(db, collectionID).withConverter(converterMap[collectionID])
}

export function getDocRef(collectionID, docID) {
    return doc(db, collectionID, docID).withConverter(converterMap[collectionID])
}

// authentication
export const useContext = createContext({ user: null })
export const useSession = () => {
    const { user } = auth.currentUser
    return {
        initilizing: !user,
        user
    }
}
export const useAuth = () => {
    const [state, setState] = useState(() => {
        const user = auth.currentUser
        return {
            initializing: !user,
            user
        }
    })

    const userDocLoader = useUserDocument(state.user?.uid)

    function onChange(user) {
        setState({ initializing: false, user })
    }
    useEffect(() => {
        console.log('use auth effect')
        // listen for auth state changes
        const unsubscribe = auth.onAuthStateChanged(onChange)
        // unsubscribe to the listener when unmounting
        return () => unsubscribe()
    }, [])

    return {
        // initializing: state.initializing || docLoader.loading,
        initializing: state.initializing,
        user: state.user,
        userDocument: userDocLoader.document,
        // docLoader: docLoader
        // docLoader: useFirestoreDocument(getDocRef(COLLECTIONS.users, state.user?.uid ?? "??"), state.user?.uid)
        // docLoader
    }
}
export const createAccount = async (email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        logEvent(analytics, 'sign_up')
        return true
    } catch (error) {
        alert(error.message)
        return false
    }
}
export const logout = async () => {
    await auth.signOut()
    logEvent(analytics, 'sign_out')
}
export const login = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password)
        logEvent(analytics, 'login')
        return true
    } catch (error) {
        alert(error.message)
        return false
    }
}
export const forgotPassword = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email)
        logEvent(analytics, 'forgot_password')
        alert("Password reset email sent")
        return true
    } catch (error) {
        alert(error.message)
        return false
    }
}