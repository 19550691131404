import { Route, Routes, useLocation } from "react-router-dom";
import TodaysPicks from './TodaysPicks';
import TodaysPicksTable from "./TodaysPicksTable";
import { useAuth } from "./firebase/firebase";
import Authentication from "./components/Authentication";
import StripePricingTable from "./components/StripePricingTable";
import SportsbooksByStatePage from "./components/SportsbooksByStatePage";
import DaySummary from "./components/DaySummary";
import Calculators from "./components/Calculators";
import ArbitragesTable from "./ArbitragesTable";
import LiveLooksPage from "./LiveLooksPage";

function App() {
  const { initializing, user, userDocument } = useAuth()
  const location = useLocation()

  console.log('loading app w user: ', userDocument?.email)
  // console.log('react app stripe publishable key: ', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

  const path = location.pathname
  const publicPaths = ['/sports-bet-calculator']

  if (initializing) {
    console.log('still initializing')
    return <div>loading</div>
  } else if (!user && !publicPaths.includes(path)) {
    return <Authentication />
  } else {
    return (
      <div className="app" style={{ backgroundColor: "white", height: "100vh" }}>
        <div>
          <Routes>
            <Route path='/' element={<TodaysPicks user={user} userDoc={userDocument} />} />
            <Route path='*' element={<TodaysPicks user={user} userDoc={userDocument} />} />
            <Route path='/sportsbooks' element={<SportsbooksByStatePage/>} />
            <Route path='/beta' element={<TodaysPicks user={user} userDoc={userDocument} showBetaFeatures={true} />} />
            <Route path='/table' element={<TodaysPicksTable user={user} userDoc={userDocument} showBetaFeatures={false} />} />
            <Route path='/day/:date' element={<DaySummary />} />
            <Route path='/arbs/:date' element={<ArbitragesTable />} />
            <Route path='/sports-bet-calculator' element={<Calculators/>} />
            <Route path='/live' element={<LiveLooksPage />} />
          </Routes>
        </div>
      </div>
    );
  }


};

export default App;