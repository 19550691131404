import logo from '../assets/PickPricer.png';

function NavBarTwo() {



    return (
        <div className="top-0 left-0 px-4 right-0 z-20 sticky h-12 bg-neutral-900 text-white items-center flex justify-between">
            <img src={logo} className='h-6'/>
            <button></button>
        </div>
    )
}

export default NavBarTwo