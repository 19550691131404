import React from "react";

function getPercentageText(number, withPlusSign) {
    if (isNaN(number)) {
        return null
    } else {
        return (number > 0 && withPlusSign ? "+" : "") + String(Math.round(number * 100)) + "%"
    }
}

function getExpectedReturnDecimalOdds(winPct, decimalOdds) {
    if (winPct && decimalOdds) {
        return (winPct * decimalOdds) - 1        
    } else {
        return null
    }
}

function getExpectedReturn(winPct, moneyLine) {
    if (moneyLine === undefined) {
        return "n/a"
    } else if (moneyLine > 0) {
        return ((winPct * moneyLine) + (1 - winPct) * -100) / 100
    } else {
        return winPct * (100 / (-1 * moneyLine)) + (1 - winPct) * -1
    }
}

// YYYYMMDD for optional date, defaults to now
function centralTimeZoneDateString(date) {
    const d = date ?? new Date()
    var dateoptions = { day: 'numeric', month: 'numeric', year: 'numeric', timeZone: 'America/Chicago' };
    const str = d.toLocaleDateString("en-US", dateoptions)
    const comps = str.split('/')
    const month = comps[0].padStart(2, '0')
    const day = comps[1].padStart(2, '0')
    return `${comps[2]}${month}${day}`
}

function getAmericanOdds(decimalPrice) {
    if (isNaN(decimalPrice)) {
        return null
    } else if (decimalPrice >= 2) {
        return "+" + String(Math.round((decimalPrice - 1) * 100))
    } else {
        return Math.round((-100) / (decimalPrice - 1))
    }
}

function getDecimalOdds(americanOdds) {
    if (isNaN(americanOdds)) {
        return null
    } else if (americanOdds > 0) {
        return 1 + (americanOdds / 100)
    } else {
        return 1 + (-100 / americanOdds)
    }
}

export { getPercentageText, getExpectedReturn, getExpectedReturnDecimalOdds, getAmericanOdds, getDecimalOdds, centralTimeZoneDateString };