import { collection, onSnapshot, query, orderBy, limit, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, getCollection } from "./firebase";
import { dayDataConverter } from "../models/DayData";

// optional converter
function useSportLeagueData(sportLeague, includedSportsbooks, date) {
    const [gamesLoading, setGamesLoading] = useState(true)
    const [gamesDoc, setGamesDoc] = useState(null)
    const [oddsLoading, setOddsLoading] = useState(true)

    const [processedData, setProcessedData] = useState([])
    const [bestMarketOutcomeOdds, setBestMarketOutcomeOdds] = useState({})
    const [worstMarketOutcomeOdds, setWorstMarketOutcomeOdds] = useState({})

    const [gamesLastUpdatedText, setGamesLastUpdatedText] = useState('')
    const [oddsLastUpdatedText, setOddsLastUpdatedText] = useState('')

    const lastupdateddateoptions = { hour: 'numeric', minute: '2-digit' };

    useEffect(() => {
        setGamesLoading(true)
        setOddsLoading(true)
        const basePath = `data/${sportLeague}/dates`
        let unsubGamesData;

        if (date) {
            const gamesDoc = doc(db, `${basePath}/${date}`).withConverter(dayDataConverter)
            unsubGamesData = onSnapshot(gamesDoc, (doc) => {
                if (doc.exists()) {
                    
                    const gamesData = doc.data()
                    // console.log('doc exists ', doc.id, sportLeague, ' => ', `${gamesData.games.length} games` )
                    gamesData.id = doc.id
                    const gamesDate = gamesData.gamesLastUpdated.toDate()
                    setGamesLastUpdatedText(gamesDate.toLocaleTimeString("en-US", lastupdateddateoptions))
                    setGamesDoc(gamesData)
                    setGamesLoading(false)
                } else {
                    setGamesLastUpdatedText('')
                    setGamesDoc(null)
                    setGamesLoading(false)
                }
            })
        } else {
            const gamesQuery = query(collection(db, basePath).withConverter(dayDataConverter), orderBy('gamesLastUpdated', 'desc'), limit(1))
            unsubGamesData = onSnapshot(gamesQuery, (querySnapshot) => {
                if (querySnapshot && querySnapshot.docs.length > 0) {
                    const gamesData = querySnapshot.docs[0].data()
                    gamesData.id = querySnapshot.docs[0].id
                    const gamesDate = gamesData.gamesLastUpdated.toDate()
                    setGamesLastUpdatedText(gamesDate.toLocaleTimeString("en-US", lastupdateddateoptions))
                    setGamesDoc(gamesData)
                    setGamesLoading(false)
                } else {
                    setGamesLastUpdatedText('')
                    setGamesDoc(null)
                    setGamesLoading(false)
                }
            })
        }


        const oddsQuery = query(collection(db, `${basePath}/${gamesDoc?.id}/odds`), orderBy('oddsLastUpdated', 'desc'), limit(1))
        const unsubOddsData = onSnapshot(oddsQuery, (querySnapshot) => {
            if (querySnapshot && querySnapshot.docs.length > 0) {
                const oddsData = querySnapshot.docs[0].data()
                oddsData.id = querySnapshot.docs[0].id

                const processedData = gamesDoc?.processedData(oddsData.odds)
                const bestMarketOutcomeOdds = gamesDoc?.bestMarketOutcomeOdds(includedSportsbooks, processedData)
                const worstMarketOutcomeOdds = gamesDoc?.worstMarketOutcomeOdds(includedSportsbooks, processedData)

                const oddsDate = oddsData.oddsLastUpdated.toDate()
                setOddsLastUpdatedText(oddsDate.toLocaleTimeString("en-US", lastupdateddateoptions))

                setProcessedData(processedData)
                setBestMarketOutcomeOdds(bestMarketOutcomeOdds)
                setWorstMarketOutcomeOdds(worstMarketOutcomeOdds)
                setOddsLoading(false)
            } else {
                setOddsLastUpdatedText('')
                setProcessedData([])
                setBestMarketOutcomeOdds({})
                setWorstMarketOutcomeOdds({})
                setOddsLoading(false)
            }
        })
        return () => {
            unsubGamesData()
            unsubOddsData()
        }
    }, [gamesDoc?.id])

    return {
        sportLeague: sportLeague,
        loading: gamesLoading || oddsLoading,
        processedData: processedData,
        bestMarketOutcomeOdds: bestMarketOutcomeOdds,
        worstMarketOutcomeOdds: worstMarketOutcomeOdds,
        gamesLastUpdatedText: gamesLastUpdatedText,
        oddsLastUpdatedText: oddsLastUpdatedText
    }
}

export default useSportLeagueData