import { getAmericanOdds, getExpectedReturnDecimalOdds, getPercentageText } from "../PicksData"
import { predictorDisplayNames } from "../TodaysPicks"
import { bgColor, getTeamPicSrc } from "./GameDataComponent"

function GameDetailsModal({ isOpen, handleClose, gameData, bestMarketOutcomeOdds, includedSportsbooks, americanStyleOdds, showTimestamps }) {

    // console.log('bmo: ', bestMarketOutcomeOdds)

    const leftGutterWidth = "w-10"
    const leftGutterHeight = "h-10"

    function oddsAreEqual(a, b) {
        if (!a || !b) {
            return false
        } else {
            return a.price === b.price && a.point === b.point
        }
    }

    function priceText(decimalPrice) {
        if (isNaN(decimalPrice)) {
            return null
        } else if (americanStyleOdds) {
            return getAmericanOdds(decimalPrice)
        } else {
            return decimalPrice
        }
    }

    function spreadText(points) {
        if (isNaN(points)) {
            return null
        } else if (points > 0) {
            return "+" + points
        } else {
            return points
        }
    }

    function Header() {
        return (
            <div>
                {/* <div className="flex justify-start text-xs font-medium">
                    <div className="bg-gray-200 px-2 py-1 rounded-tl rounded-br">{gameData.timeText}</div>
                </div> */}
                <div className="flex flex-row justify-between w-full">
                    <div className="px-2 py-1 text-sm font-medium">{gameData.timeText}</div>
                    <button className="mr-2 text-sm font-medium" onClick={handleClose}>Close</button>
                </div>
            </div>

        )
    }

    function DataCell({ primaryText, secondaryText, highlighted }) {
        return (
            <div className={`w-1/2 flex flex-col items-center justify-center ${leftGutterHeight} ${highlighted ? 'bg-green-200' : ''}`}>
                <label className="text-sm font-semibold">{primaryText}</label>
                <label className="text-xs">{secondaryText}</label>
            </div>
        )
    }

    function TeamLogoName({ isHome, customClass, shortened, height }) {
        const teamid = isHome ? gameData.homeid : gameData.awayid
        const teamAbbrev = isHome ? gameData.homeAbbrev : gameData.awayAbbrev

        return (
            <div className={`flex flex-row items-center space-x-2 ${customClass}`}>
                <img src={getTeamPicSrc(gameData.league, teamid, teamAbbrev)} className={`${height}`} />
                <label className="text-sm font-semibold">{isHome ? (shortened ? gameData.homeLocation : gameData.homeTeam) : (shortened ? gameData.awayLocation : gameData.awayTeam)}</label>
            </div>
        )
    }

    function PredictionBreakdown({ prediction }) {
        return (
            <div className="border rounded">
                <SectionHeader imgSrc={`/images/${prediction.provider.toLowerCase()}_square_transparent.png`} text={predictorDisplayNames[prediction.provider] + " Expected Value"} />
                <div className="flex flex-row space-x-2 p-2">
                    {
                        [false, true].map(isHome => {
                            const winpct = isHome ? prediction.homeWinPct : prediction.awayWinPct
                            const moneyline = isHome ? bestMarketOutcomeOdds['h2h::' + (gameData?.oddsApiHomeTeam)].price : bestMarketOutcomeOdds['h2h::' + (gameData?.oddsApiAwayTeam)].price
                            const expreturn = getExpectedReturnDecimalOdds(winpct, moneyline)
                            const bottomTextClassname = expreturn > 0 ? 'font-bold' : 'font-regular'
                            return (
                                <div key={isHome} className={`flex flex-col space-y-2 w-1/2`}>
                                    <TeamLogoName isHome={isHome} shortened={true} customClass={'pl-2'} height={'h-8'} />
                                    <div className={`flex flex-col items-center py-1 rounded border-2 ${expreturn > 0 ? 'bg-green-200 text-black border-green-800' : 'border-gray-100 bg-gray-100 text-gray-700'}`}>
                                        <label className="text-lg font-bold">{getPercentageText(expreturn, true)}</label>
                                        <label className="text-xs font-regular">Expected Return</label>
                                    </div>
                                    <div className="flex flex-row justify-between text-xs">
                                        <label className={bottomTextClassname}>Proj Win%</label>
                                        <label className={bottomTextClassname}>{getPercentageText(winpct, false)}</label>
                                    </div>
                                    <div className="flex flex-row justify-between text-xs">
                                        <label className={bottomTextClassname}>Moneyline Odds</label>
                                        <label className={bottomTextClassname}>{getAmericanOdds(moneyline)}</label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    function SectionHeader({ imgSrc, text }) {
        return (
            <div className="bg-gray-100 flex flex-row items-center px-2 py-2 rounded-t">
                <img src={imgSrc} className="h-8" />
                <label className="pl-2 text-medium font-medium">{text}</label>
            </div>
        )
    }

    function OddsComparisionBreakdown() {
        return (
            <div className="border rounded">
                <SectionHeader imgSrc={`/images/pickpricer_square.png`} text={'Odds Comparison'} />
                <div className="flex flex-row">
                    <div className={leftGutterWidth} />
                    <div className="flex flex-row grow">
                        <TeamLogoName isHome={false} customClass={`w-1/2 ${awayTeamColor} px-2 py-1`} shortened={true} height={'h-8'} />
                        <TeamLogoName isHome={true} customClass={`w-1/2 ${homeTeamColor} px-2 py-1`} shortened={true} height={'h-8'} />
                    </div>
                </div>

                <div className="flex flex-row text-xs text-center">
                    <div className={leftGutterWidth} />
                    <div className="flex flex-row grow">
                        {
                            ['Moneyline', 'Spread', 'Moneyline', 'Spread'].map((t, i) => {
                                return <div key={i} className={`w-1/4 text-center text-xs ${i > 1 ? homeTeamColor : awayTeamColor}`}>{t}</div>
                            })
                        }
                    </div>
                </div>

                {
                    gameData.gameOdds.bookmakers.filter(b => {
                        return includedSportsbooks.includes(b.key)
                    }).map(b => {
                        const organizationKey = b.key
                        const organizationTitle = b.title
                        const lastUpdateDate = new Date(b.last_update)
                        
                        const dateoptions = { hour: 'numeric', minute: '2-digit', second: '2-digit' };

                        const h2hMarket = b.markets.find(m => { return m.key === 'h2h' })
                        const spreadsMarket = b.markets.find(m => { return m.key === 'spreads' })
                        const totalsMarket = b.markets.find(m => { return m.key === 'totals' })

                        const h2hHomeTeamOdds = h2hMarket?.outcomes.find(o => { return o.name === gameData.oddsApiHomeTeam })
                        const h2hAwayTeamOdds = h2hMarket?.outcomes.find(o => { return o.name === gameData.oddsApiAwayTeam })

                        const spreadsHomeTeamOdds = spreadsMarket?.outcomes.find(o => { return o.name === gameData.oddsApiHomeTeam })
                        const spreadsAwayTeamOdds = spreadsMarket?.outcomes.find(o => { return o.name === gameData.oddsApiAwayTeam })

                        const totalsOverOdds = totalsMarket?.outcomes.find(o => { return o.name === 'Over' })
                        const totalsUnderOdds = totalsMarket?.outcomes.find(o => { return o.name === 'Under' })

                        let lastUpdatedText = lastUpdateDate.toLocaleTimeString('en-US', dateoptions)

                        return <div key={organizationKey} className="flex flex-row">
                            <img src={`/images/${organizationKey}_square.png`} className={leftGutterWidth} />
                            <div className="flex grow">

                                <div className={`w-1/2 flex flex-row ${awayTeamColor}`}>
                                    <DataCell primaryText={priceText(h2hAwayTeamOdds?.price)} secondaryText={showTimestamps ? lastUpdatedText:null} highlighted={oddsAreEqual(bestMarketOutcomeOdds['h2h::' + gameData.oddsApiAwayTeam], h2hAwayTeamOdds)} />
                                    <DataCell primaryText={spreadText(spreadsAwayTeamOdds?.point)} secondaryText={priceText(spreadsAwayTeamOdds?.price)} highlighted={oddsAreEqual(bestMarketOutcomeOdds['spreads::' + gameData.oddsApiAwayTeam], spreadsAwayTeamOdds)} />
                                </div>
                                <div className={`w-1/2 flex flex-row ${homeTeamColor}`}>
                                    <DataCell primaryText={priceText(h2hHomeTeamOdds?.price)} secondaryText={showTimestamps ? lastUpdatedText:null} highlighted={oddsAreEqual(bestMarketOutcomeOdds['h2h::' + gameData.oddsApiHomeTeam], h2hHomeTeamOdds)} />
                                    <DataCell primaryText={spreadText(spreadsHomeTeamOdds?.point)} secondaryText={priceText(spreadsHomeTeamOdds?.price)} highlighted={oddsAreEqual(bestMarketOutcomeOdds['spreads::' + gameData.oddsApiHomeTeam], spreadsHomeTeamOdds)} />
                                </div>
                                {/* <div className={`w-1/3 flex flex-row ${overUnderColor}`}>
                                        <DataCell primaryText={totalsOverOdds && "o" + totalsOverOdds?.point} secondaryText={priceText(totalsOverOdds?.price)} highlighted={oddsAreEqual(bestMarketOutcomeOdds['totals::Over'], totalsOverOdds)} />
                                        <DataCell primaryText={totalsUnderOdds && "u" + totalsUnderOdds?.point} secondaryText={priceText(totalsUnderOdds?.price)} highlighted={oddsAreEqual(bestMarketOutcomeOdds['totals::Under'], totalsUnderOdds)} />
                                    </div> */}
                            </div>
                        </div>
                    })
                }


            </div>
        )
    }

    // function SectionHeaderRow({ texts }) {
    //     return (
    //         <div className="flex flex-row bg-gray-600 text-white text-xs text-center">
    //             <div className={leftGutterWidth} />
    //             <div className="flex flex-row grow">
    //                 {
    //                     texts.map(t => {
    //                         return <div className="w-1/4 text-center">{t}</div>
    //                     })
    //                 }
    //             </div>
    //         </div>
    //     )
    // }

    // function PredictionRow({ prediction }) {
    //     return (<div className="flex flex-row">
    //         <img src={`/images/${prediction.provider.toLowerCase()}_square.png`} className={`${leftGutterWidth}`} />
    //         <div className="flex flex-row grow">
    //             <TeamPrediction isHome={false} winPct={prediction.awayWinPct} expReturn={getExpectedReturnDecimalOdds(prediction.awayWinPct, bestMarketOutcomeOdds['h2h::' + (gameData?.oddsApiAwayTeam)].price)} />
    //             <TeamPrediction isHome={true} winPct={prediction.homeWinPct} expReturn={getExpectedReturnDecimalOdds(prediction.homeWinPct, bestMarketOutcomeOdds['h2h::' + (gameData?.oddsApiHomeTeam)].price)} />
    //         </div>
    //     </div>)
    // }

    // function TeamPrediction({ isHome, winPct, expReturn }) {
    //     return (
    //         <div className={`${bgColor(expReturn) ?? (isHome ? homeTeamColor : awayTeamColor)} ${leftGutterHeight} ${expReturn > 0 ? 'text-white' : 'text-black'} flex flex-row w-1/2`}>
    //             <DataCell primaryText={getPercentageText(winPct, false)} secondaryText={null} />
    //             <DataCell primaryText={getPercentageText(expReturn, true)} secondaryText={null} />
    //         </div>
    //     )



    // }

    // function TeamHeaderCell({ isHome, teamName, teamAbbrev, teamId, league }) {
    //     return (
    //         <div className={`w-1/2 flex flex-col items-center ${isHome ? homeTeamColor : awayTeamColor} rounded-t-lg`}>
    //             <img src={getTeamPicSrc(league, teamId, teamAbbrev)} className="w-12 h-12 mt-2" />
    //             <label className="text-xs font-semibold text-center p-2">{teamName}</label>
    //         </div>
    //     )
    // }

    const awayTeamColor = 'bg-white'
    const homeTeamColor = 'bg-gray-100'
    const overUnderColor = 'bg-gray-100'

    if (isOpen) {
        return (
            <div onClick={handleClose} className="z-30 fixed bg-black bg-opacity-75 w-full min-h-screen grow relative">
                <div className="flex flex-col space-y-2 bg-white m-auto rounded grow relative max-w-4xl p-2 mt-4 mb-4">
                    <Header />
                    {
                        gameData.predictions.map(p => {
                            return <PredictionBreakdown key={p.provider} prediction={p} />
                        })
                    }
                    <OddsComparisionBreakdown />
                </div>

            </div>
        )
    } else {
        return <></>
    }

}

export default GameDetailsModal