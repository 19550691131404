import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, getCollection } from "./firebase";

// optional converter
function useFirestoreQuery(collectionPath, queryConstraints, sortFunction, reload) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [results, setResults] = useState([])
    
    useEffect(() => {
        setLoading(true)

        const col = collection(db, collectionPath)

        const q = query(col, ...queryConstraints)
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            if (querySnapshot && querySnapshot.docs.length > 0) {
                const res = []
                querySnapshot.docs.forEach(d => {
                    const s = d.data()
                    s.id = d.id
                    res.push(s)
                })
                if (sortFunction) {
                    res.sort(sortFunction)
                }
                setResults(res)
                setLoading(false)
            } else {
                setEmpty()
            }
        })
        return () => unsubscribe()
    }, [reload])

    function setEmpty() {
        setResults([])
        setLoading(false)
    }

    return {
        error,
        loading,
        results
    }
}

export default useFirestoreQuery