import { useState } from "react"
import { createAccount, login, forgotPassword } from "../firebase/firebase"
import { useNavigate } from "react-router-dom"
import logo from '../assets/pickpricer_lockup.png';

function TextInputComponent({ title, update, type }) {
    return (
        <div className="flex flex-col space-y-0.5 w-full">
            <label className="text-sm pl-1">{title}</label>
            <input className={`w-full border rounded p-1 bg-gray-100`} type={type} onChange={(e) => update(e.target.value)} />
        </div>
    )
}

function Authentication() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [isLogin, setIsLogin] = useState(false)
    const [loading, setLoading] = useState(false)

    async function authSubmit(e) {
        e.preventDefault()
        if (!email) {
            alert("Please enter an email")
            return
        } else if (!password) {
            alert("Please enter a password")
            return
        } else if (!isLogin && passwordConfirm !== password) {
            alert("Passwords do not match")
            return
        }

        setLoading(true)
        let success
        if (isLogin) {
            success = await login(email, password)
        } else {
            success = await createAccount(email, password)
        }
        if (!success) {
            setLoading(false)
        }
    }

    async function resetPassword() {
        if (!email) {
            alert("Please enter an email")
            return
        }

        let success = await forgotPassword(email)
    }

    if (loading) {
        return <div>loading...</div>
    }

    return (
        <div className="flex h-screen px-2 bg-gray-100">
            <div className="flex flex-col p-2 items-center m-auto border rounded grow max-w-lg bg-white">
                <img src={logo} className="w-1/2 my-4" />
                <form className="w-full flex flex-col space-y-2" onSubmit={authSubmit}>
                    <TextInputComponent key={"Email"} title={"Email"} update={setEmail} type="email" />
                    <TextInputComponent key={"Password"} title={"Password"} update={setPassword} type="password" />
                    {!isLogin &&
                        <TextInputComponent key={"Confirm Password"} title={"Confirm Password"} update={setPasswordConfirm} type="password" />
                    }
                    <button className="w-full rounded py-2 bg-charcoal text-white font-semibold" type="submit">{isLogin ? 'Log In' : 'Create account'}</button>
                </form>
                <div className="mt-2">
                    <label className="text-sm">{isLogin ? "Don't have an account yet? " : 'Already have an account? '}</label>
                    <button className="text-sm font-semibold text-blue-600" onClick={(e) => setIsLogin(!isLogin)}>{isLogin ? 'Create account' : 'Log in'}</button>
                </div>
                {
                    isLogin &&
                    <div className="mt-2">
                        <label className="text-sm">Forgot Your Password? </label>
                        <button className="text-sm font-semibold text-blue-600" onClick={(e) => resetPassword()}>Send Password Reset Email</button>
                    </div>
                }

            </div>
        </div>
    )
}

export default Authentication