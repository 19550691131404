function NoGamesComponent({ leagueName, isArb }) {
    return (
        <div className="bg-white rounded w-full p-3">
            {
                isArb &&
                <label className="text-sm text-black font-medium">No current arb bets available. Most opportunities are while games are in progress, so be sure to check back later!</label>
            }
            {
                !isArb &&
                <label className="text-sm text-black font-medium">No upcoming {leagueName} games today. Check back tomorrow!</label>
            }
            
        </div>
    );
}

export default NoGamesComponent;